import { Card, Col, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useApiUrl, useCustom } from "@refinedev/core";
import { numberToK } from "../../../../utils";

type StatisticTeamProps = {
  teamId: string
}

const initialMembers = {
  current: 0
}

const StatisticTeam = ({teamId}: StatisticTeamProps) => {
  const [members, setMembers] = useState({
    admin_count: initialMembers,
    total_count: initialMembers,
    viewer_count: initialMembers,
    member_count: initialMembers
  })
  const [attachmentsSize, setAttachmentsSize] = useState(0)
  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom({
    url: `${apiUrl}/perm/teamMetrics`,
    method: "get",
    config: {
      query: {
        teamId
      },
    },
  });
  const { data: docsData, isLoading: isDocsLoading } = useCustom({
    url: `${apiUrl}/documents`,
    method: "get",
    config: {
      query: {
        limit: 1,
        'filter[0]': 'teamId||$eq||' + teamId
      }
    }
  });

  useEffect(() => {
    const teamData = data?.data.metrics.team
    if (teamData?.members) setMembers(teamData.members)
    if (teamData?.attachments.total_size.current) setAttachmentsSize(teamData.attachments.total_size.current)
  }, [data?.data.metrics.team, isLoading])

  return <Col
    style={{
      backgroundColor: "#f0f2f5",
      padding: "10px 10px",
      borderRadius: "5px"
    }}
  >
    <Row justify="space-between" gutter={[5, 10]}>
      <Col span={6}>
        <Card size="small">
          <Statistic
            title="Members"
            value={members.member_count?.current}
            loading={isLoading}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} size="small">
          <Statistic
            title="Guests"
            value={members.viewer_count.current}
            loading={isLoading}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} size="small">
          <Statistic
            title="Admins"
            value={members.admin_count.current}
            loading={isLoading}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} size="small">
          <Statistic
            title="Total users"
            value={members.total_count.current}
            loading={isLoading}
          />
        </Card>
      </Col>
    </Row>
    <Row justify="space-between" gutter={[5, 10]} style={{marginTop: '5px'}}>
      <Col span={6}>
        <Card bordered={false} size="small">
          <Statistic
            title="Общий объем файлов"
            value={numberToK(attachmentsSize,'b')}
            loading={isLoading}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} size="small">
          <Statistic
            title="Всего документов"
            value={numberToK(docsData?.data.total)}
            loading={isDocsLoading}
          />
        </Card>
      </Col>
    </Row>
  </Col>
}
export default StatisticTeam
