import YonoteLogo from '../YonoteLogo';

const Title = ({ collapsed }: { collapsed: boolean }) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  }}>
    <YonoteLogo width="90%" />
    {
      !collapsed ? (
        <div style={ { marginLeft: '5px', marginTop: '3px', color: '#d46b08' } }>ADMIN</div>
      ) : null
    }
  </div>
)

export default Title
