import {Button, Form, FormInstance} from "antd";
import React, {FC, ReactNode} from "react";

type SubmitButtonProps = {
  form: FormInstance;
  onClick: () => void;
  children: ReactNode;
}

const SubmitButton: FC<SubmitButtonProps> = ({form, onClick, children}) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({validateOnly: true})
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button type="primary" onClick={onClick} disabled={!submittable}>
      {children}
    </Button>
  );
};

export default SubmitButton;
