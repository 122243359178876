import * as React from "react";
import { createRoot }  from 'react-dom/client';
import App from "./App";
import KeycloakProvider from "./components/KeycloakProvider";
import relativeTime from "dayjs/plugin/relativeTime";
import * as dayjs from "dayjs";
dayjs.extend(relativeTime);

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <KeycloakProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </KeycloakProvider>
);


