import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { Button, Row, Select } from "antd";
import { CrudFilter, useCreate, useList } from "@refinedev/core";
import { ITariffModel } from "../pages/Billing/models/TariffModel";
import { numberToPrice } from "../utils";
import { debounce } from "lodash";
import { PlusCircleOutlined } from "@ant-design/icons";

export type TariffSelectProps = {
  key?: string
  label?: React.ReactNode
  value?: string | number
  style?: CSSProperties,
  updateTableTariff?: () => void,
  teamId?: string
};

export const TariffSelect: React.FC<TariffSelectProps> = ({
  style,
  updateTableTariff,
  teamId,
}) => {
  const [searchText, setSearchText] = useState<string>();
  const [selectTariff, setSelectTariff] = useState('')
  const { mutate } = useCreate();

  const filters = useMemo(() => {
    const filters: CrudFilter[] = [];
    if (searchText) {
      filters.push({ field: 'name', operator: 'contains', value: searchText })
    }
    return filters;
  }, [searchText]);

  const searchResult = useList<ITariffModel>({
    resource: 'tariff',
    config: {
      filters: filters
    }
  });

  const setSearchTextDebounce = useMemo(() => {
    return debounce(setSearchText, 200);
  }, []);

  const onSelect = useCallback((id: string) => {
    setSelectTariff(id)
  }, [])

  const addTariff = useCallback(() => {
    mutate(
      {
        resource: `tariff_to_team`,
        values: {
          teamId: teamId,
          tariffId: selectTariff
        },
      },
    )
    setSelectTariff('')
    updateTableTariff && updateTableTariff()
  }, [selectTariff])

  return (<Row style={{...style, display: "flex"}}>
      <Select
        showSearch
        allowClear
        filterOption={false}
        onSearch={setSearchTextDebounce}
        loading={searchResult.isFetching}
        style={{
          display: "flex",
          flexGrow: "1",
        }}
        onSelect={onSelect}
        value={selectTariff}
      >
        {
          searchResult.data?.data.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {`${item.name} / ${numberToPrice(item.price)}`}
            </Select.Option>
          ))
        }
      </Select>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={addTariff}
        disabled={!selectTariff}
      />
    </Row>
  );
}
