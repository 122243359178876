import ReactJson from "react-json-view";
import { Modal } from "antd";
import React, { useState } from "react";

export default function useMetaViewer() {
  const [meta, setMeta] = useState<any>();

  return {
    showMeta: setMeta,
    metaComponent: (
      <Modal
        open={!!meta}
        onCancel={() => setMeta(null)}
        footer={null}
        title="Meta"
        destroyOnClose
      >
        <ReactJson src={meta} />
      </Modal>
    )
  }
}
