import { CrudFilter, IResourceComponentsProps } from "@refinedev/core";
import React, { useMemo } from "react";
import {
  DateField,
  DeleteButton,
  EditButton,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import TableTitleWithCount from "../../../components/TableTitleWithCount";
import { Button, Checkbox, Select, Table } from "antd";
import {
  CheckCircleOutlined,
  MinusOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { IPaymentMethodModel } from "../models/PaymentMethodModel";
import { ListProps } from "@refinedev/antd/dist/components/crud/types";
import { Link } from "react-router-dom";

export type PaymentMethodListProps = IResourceComponentsProps & {
  teamId?: string;
  userId?: string;
  paymentGateway?: string;
  isActive?: boolean;
  type?: string;
  canCreate?: boolean;
  listProps?: Partial<ListProps>;
};

export const PaymentMethodList: React.FC<PaymentMethodListProps> = ({
  teamId,
  userId,
  paymentGateway,
  isActive,
  type,
  canCreate = true,
  listProps,
}) => {
  const filter: CrudFilter[] = useMemo(() => {
    const filter: CrudFilter[] = [];
    if (teamId) {
      filter.push({
        field: "teamId",
        operator: "eq",
        value: teamId,
      });
    }
    if (userId) {
      filter.push({
        field: "userId",
        operator: "eq",
        value: userId,
      });
    }
    if (paymentGateway) {
      filter.push({
        field: "paymentGateway",
        operator: "eq",
        value: paymentGateway,
      });
    }
    if (isActive) {
      filter.push({
        field: "isActive",
        operator: "eq",
        value: isActive,
      });
    }
    if (type) {
      filter.push({
        field: "type",
        operator: "eq",
        value: type,
      });
    }
    return filter;
  }, [isActive, paymentGateway, teamId, type, userId]);

  const { tableProps, sorter, tableQueryResult } =
    useTable<IPaymentMethodModel>({
      resource: "payment_method",
      initialFilter: filter,
      initialSorter: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      initialPageSize: 50,
    });

  return (
    <List
      title={
        <TableTitleWithCount
          title="PaymentMethods"
          tableQueryResult={tableQueryResult}
        />
      }
      resource="payment_method"
      canCreate={false}
      headerButtons={
        canCreate && (
          <Link
            to={"/payment_method/create" + (teamId ? "?teamId=" + teamId : "")}
          >
            <Button>Create</Button>
          </Link>
        )
      }
      {...listProps}
    >
      <Table<IPaymentMethodModel> {...tableProps} size="small" rowKey="id">
        <Table.Column
          dataIndex={["id"]}
          key="id"
          title="ID"
          render={(value) => (
            <EditButton
              type="link"
              recordItemId={value}
              resource="payment_method"
            >
              {value}
            </EditButton>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: "100%", minWidth: "200px" }}
                placeholder="Select ID"
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={["name"]}
          key="name"
          title="Name"
          render={(value, record: IPaymentMethodModel) => (
            <EditButton
              type="link"
              recordItemId={record.id}
              resource="payment_method"
            >
              {value}
            </EditButton>
          )}
        />
        <Table.Column
          dataIndex={["isActive"]}
          key="isActive"
          title="IsActive"
          render={(value) =>
            value ? <CheckCircleOutlined /> : <MinusOutlined />
          }
          defaultSortOrder={getDefaultSortOrder("isActive", sorter)}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Checkbox>Is active</Checkbox>
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={["paymentGateway"]}
          key="paymentGateway"
          title="Gateway"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: "100%", minWidth: "200px" }}
                placeholder="Select ID"
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={["team", "subdomain"]}
          key="teamId"
          title="Team"
          render={(value, record: IPaymentMethodModel) => (
            <ShowButton
              type="link"
              icon={<TeamOutlined />}
              recordItemId={record?.teamId}
              resource="teams"
            >
              {value}
            </ShowButton>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => (
            <DateField format="DD.MM.YY HH:mm:ss" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="updatedAt"
          render={(value) => (
            <DateField format="DD.MM.YY HH:mm:ss" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column
          render={(_, record: any) => (
            <DeleteButton
              resource="payment_method"
              recordItemId={record.id}
              hideText
            />
          )}
        />
      </Table>
    </List>
  );
};
