import React, {FC, useCallback, useState} from "react";
import {Button, Form, Input, Modal, Space, Switch} from "antd";
import SubmitButton from "./SubmitButton";

export type DumpModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk: (formValues: DumpValues) => void;
}

export type DumpValues = {
  newTeamId: string;
  newUserId: string;
  newBucketUrl: string;
};

const defaultValues = {
  newTeamId: "",
  newUserId: "",
  newBucketUrl: "",
}

const DumpModal: FC<DumpModalProps> = ({
  onOk,
  open,
  onCancel,
}) => {
  const [formValues, setFormValues] = useState<DumpValues>(defaultValues);
  const [withUsers, setWithUsers] = useState(false);

  const [form] = Form.useForm();

  const onReset = useCallback(() => {
    form.resetFields();
    setWithUsers(false);
  }, []);

  return (
    <Modal
      title={"Дамп"}
      open={open}
      onCancel={() => {
        onCancel();
        onReset();
      }}
      footer={[
        <Form.Item>
          <Space>
            <SubmitButton
              form={form}
              onClick={() => {
                onOk(formValues);
                onReset();
              }}
            >
              Submit
            </SubmitButton>
            <Button
              onClick={onReset}
            >
              Reset
            </Button>
          </Space>
        </Form.Item>
      ]}
      destroyOnClose
    >
      <Form layout="vertical" onValuesChange={(_, values) => setFormValues(values)} form={form}>
        <Form.Item label="newTeamId" name="newTeamId" rules={[{required: true}]}>
          <Input placeholder={"newTeamId"}/>
        </Form.Item>
        <Form.Item label="newUserId" name="newUserId" rules={[{required: true}]}>
          <Input placeholder={"newUserId"}/>
        </Form.Item>
        <Form.Item label="newBucketUrl" name="newBucketUrl" rules={[{required: true}]}>
          <Input placeholder={"newBucketUrl"}/>
        </Form.Item>
        <Form.Item label="withUsers" name="withUsers">
          <Switch onChange={setWithUsers} />
        </Form.Item>
        {withUsers && (
          <Form.Item
            label="adminEmail"
            name="adminEmail"
            dependencies={['withUsers']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue('withUsers')) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('adminEmail is required!'));
                },
              }),
            ]}
          >
            <Input placeholder={"adminEmail"}/>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default DumpModal;
