import React, { useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { CrudFilters, useList } from "@refinedev/core";
import { SelectProps } from "antd/es/select";

export type SelectItemProps = {
  resource: string
  labelKey?: string
  valueKey?: string
  filters?: CrudFilters
} & Partial<SelectProps>

export const SelectItem: React.FC<SelectItemProps> = ({
  resource,
  labelKey = 'name',
  valueKey = 'id',
  filters,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');

  const localFilters = useMemo(() => {
    let prepareFilters: CrudFilters = [];
    if (searchText) {
      prepareFilters = [{
        operator: 'or',
        value: [
          {
            field: labelKey,
            operator: 'contains',
            value: searchText,
          }
        ]
      }];
    } else if (props.value) {
      prepareFilters = [{
        field: 'id',
        operator: 'eq',
        value: props.value,
      }]
    }
    if (filters) {
      prepareFilters = prepareFilters.concat(filters);
    }
    return prepareFilters;
  }, [filters, labelKey, props.value, searchText])

  const { isLoading, data } = useList({
    resource,
    config: {
      filters: localFilters.length ? localFilters : undefined,
      pagination: {
        pageSize: 50,
      }
    }
  })

  return (
    <Select
      showSearch
      filterOption={false}
      onSearch={value => setSearchText(value)}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      fieldNames={{label: labelKey, value: valueKey}}
      {...props}
      options={data?.data || []}
    />
  );
}
