import { useShow, IResourceComponentsProps } from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Typography } from "antd";
import React from "react";
import {IQuotaModel} from "../models/QuotaModel";
const { Title, Text } = Typography;

export const QuotaShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IQuotaModel>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}></Title>

      {/*<Title level={4}>Links</Title>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Web: </strong> {record?.web || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Linkedin: </strong> {record?.linkedin || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Twitter: </strong> {record?.twitter || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Instagram: </strong> {record?.instagram || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Youtube: </strong> {record?.youtube || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Github: </strong> {record?.github || "-"}*/}
      {/*</Paragraph>*/}
    </Show>
  );
};
