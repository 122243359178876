import React, { useState } from "react";
import {IResourceComponentsProps} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import {IQuotaModel} from "../models/QuotaModel";
import TransferActions from "../components/TransferActions";

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
  const {formProps, saveButtonProps} = useForm<IQuotaModel>();
  const [selectedIdent, setSelectedIdent] = useState('');

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={(changed) => {
          changed.ident && setSelectedIdent(changed.ident);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  label="Value"*/}
        {/*  name="value"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <Input*/}
        {/*    placeholder={ QuotaIdents[selectedIdent] ? QuotaIdents[selectedIdent].values : 'Enter value' }*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  label="Actions"*/}
        {/*  name="actions"*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <TransferActions />*/}
        {/*</Form.Item>*/}
      </Form>
    </Create>
  );
};
