import { useTable, useTableProps } from "@refinedev/antd";
import { Input } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import useDebouncedState from "../../../hooks/useDebouncedState";
import { BaseRecord, CrudFilter, HttpError } from "@refinedev/core";

interface useTransferTableProps<TData, TError, TSearchVariables>
  extends useTableProps<TData, TError, TSearchVariables>{}

function useTransferTable <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TSearchVariables = unknown
  >(
    {
      ...tableProps
    }: useTransferTableProps<TData, TError, TSearchVariables>){
  const [searchText, setSearchText] = useState<Record<string, string | undefined>>({});
  const debouncedSearchText = useDebouncedState<Record<string, string| undefined>>(
    searchText,
    500
  );

  const onInputChange = useCallback(
    (columnName: string, value: string | undefined) => {
      setSearchText({...searchText, [columnName]: !!value ? value : undefined});
    },
    [searchText]
  );
  const getColumn = useCallback((columnName: string) => {
    return <Input
      autoFocus
      name="filter-dropdown-input"
      value={searchText[columnName] || ""}
      size="middle"
      placeholder={`Search in ${columnName}`}
      onChange={(event) => onInputChange(columnName, event.target.value)}
    />
  }, [onInputChange, searchText])

  const filters = useMemo<CrudFilter[]>(() => {
    return Object.keys(searchText).map((key) => {
      const value = debouncedSearchText[key] ?? null;

      return {
        field: key,
        operator: key === "id" ? "in" : "contains",
        value: key === "id" && value !== null ? [value] : value,
      };
    })
  }, [getColumn, debouncedSearchText]);

  const tableData = useTable<TData, TError, TSearchVariables>({
    ...tableProps,
    permanentFilter: tableProps.permanentFilter
      ? [...tableProps.permanentFilter, ...filters]
      : filters,
    initialPageSize: 100
  });

  return {
    tableData,
    getColumn,
  };
}

export default useTransferTable;
