import React, { FC, useCallback } from "react";
import { IQuotaModel } from "../models/QuotaModel";
import { ShortId } from "../../../components/ShortId";
import { TransferProps } from "antd";
import useTransferTable from "../hooks/useTransferTable";
import TransferComponent from "./TransferComponent";

interface TransferQuotasProps {
  value?: string[];
  onChange?: (items: string[]) => void;
}

const TransferQuotas: FC<TransferQuotasProps> = ({ value, onChange }) => {
  const { tableData: { tableProps }, getColumn} = useTransferTable({ resource: "quota" });
  const handleChange: TransferProps<IQuotaModel>["onChange"] = useCallback(
    (keys) => {
      onChange?.(keys)
    },
    [onChange,  value]
  );

  const tableColumns = [
    {
      dataIndex: "id",
      title: "ID",
      render: ShortId,
      filterDropdown: () => getColumn("id")
    },
    {
      dataIndex: "name",
      title: "Name",
      filterDropdown: () => getColumn("name")
    },
  ]

  return (
    <TransferComponent<IQuotaModel>//@ts-ignore
      dataSource={tableProps.dataSource || []}
      targetKeys={value || []}
      onChange={handleChange}
      tableProps={{
        leftColumns: tableColumns,
        rightColumns: tableColumns,
        pagination: { pageSize: 100 },
      }}
    />
  );
};

export default TransferQuotas;
