import { Typography } from "antd";
import * as React from "react";

export default function TableTitleWithCount({
  title,
  tableQueryResult,
}: {
  title: string;
  tableQueryResult: any;
}) {
  return (
    <>
      {title}{" "}
      <small>
        <Typography.Text
          type="secondary"
          title={"Всего по фильтру: " + tableQueryResult?.data?.total}
        >
          {"(Всего: "}
          {tableQueryResult?.data?.total}
          {" / На странице: "}
          {tableQueryResult?.data?.data?.length || "0"}
          {")"}
        </Typography.Text>
      </small>
    </>
  );
}
