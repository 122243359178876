import { AutoComplete } from "antd";
import React, { useMemo } from "react";

const IdentInput: React.FC = (props) => {
  const idents = props.idents as {label: string; values: string}[];
  const renderItem = useMemo(() => (ident: string) => ({
    value: ident,
    label: (
      <div>
        {ident}
        <div>
          <small>{idents[ident].label}</small>
        </div>
      </div>
    )
  }), [idents]);

  return (
    <AutoComplete
      allowClear
      options={Object.keys(idents).map(key => renderItem(key))}
      {...props}
    />
  )
}

export default IdentInput;
