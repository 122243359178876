import React from "react";
import { useCustom } from "@refinedev/core";
import { Conversation, ConversationsResponse } from "../interfaces/FreeScout";
import { Alert, Skeleton, List, Badge, Avatar } from "antd";
import { FREESCOUT_URL, REACT_APP_FREESCOUT_API_KEY } from "../constants";

const conversationStatusColor = {
  active: 'red',
  pending: 'orange',
  closed: 'yellow',
}

export type UserTicketsProps = {
  email: string
}

const UserTickets: React.FC<UserTicketsProps> = ({
  email
}) => {
  const { data, isLoading } = useCustom<ConversationsResponse>({
    url: `${FREESCOUT_URL}/api/conversations`,
    method: 'get',
    config: {
      headers: {
        'X-FreeScout-API-Key': REACT_APP_FREESCOUT_API_KEY
      },
      query: {
        customerEmail: email
      }
    }
  });

  if (isLoading) {
    return <Skeleton active />
  }

  if (!data?.data) {
    return <Alert message="Запросов в поддержку нет" type="info" />
  }

  return (
    <List
      size="small"
      header={<div>Запросы в поддержку</div>}
      bordered
      dataSource={data.data && data.data._embedded.conversations}
      renderItem={(item: Conversation) => (
        <List.Item>
          <List.Item.Meta
            title={(
              <a href={`${FREESCOUT_URL}/conversation/${item.number}?folder_id=${item.folderId}`} target="_blank">
                {item.subject}
              </a>
            )}
            description={<>
              {item.preview}
              <hr />
              <div>
                Статус: <Badge count={item.status} showZero color={conversationStatusColor[item.status]} />
                {' '}
                Назначен(а): <Avatar src={<img src={item.assignee.photoUrl} alt="avatar" />} />
                {' ' + item.assignee.firstName + ' ' + item.assignee.lastName}
              </div>
            </>}
          />
        </List.Item>
      )}
    />
  )
}

export default UserTickets;
