import React from "react";
import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
  DateField,
  Show,
  ShowButton,
} from "@refinedev/antd";
import {
  Col,
  Row,
  Space,
  Switch,
  Table,
  Typography,
  Descriptions,
} from "antd";
import {ITariffModel} from "../models/TariffModel";
const { Title, Text } = Typography;

export const TariffShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ITariffModel>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Row gutter={[24, 24]} style={{marginBottom: '25px'}}>
        <Col span={12}>
          <Title level={5}>Name</Title>
          <Text>{record?.name}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Active</Title>
          <Switch checked={record?.isActive} disabled={true} />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{marginBottom: '25px'}}>
        <Col span={12}>
          <Title level={5}>Description</Title>
          <Text>{record?.description}</Text>
        </Col>
        <Col span={12}>
          <Descriptions>
            <Descriptions.Item label="Updated">
              <DateField format="MM.DD.YY hh:mm" value={record?.updatedAt}/>
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              <DateField format="MM.DD.YY hh:mm" value={record?.createdAt}/>
            </Descriptions.Item>
            <Descriptions.Item label="Deleted">
              {
                record?.deletedAt ? (
                  <DateField format="MM.DD.YY hh:mm" value={record?.deletedAt}/>
                ) : 'n/a'
              }
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Title level={5}>Included Quotas</Title>
      <Table
        size="small"
        dataSource={data?.data.quotas}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
          },
          {
            title: 'IDENT',
            dataIndex: 'ident',
            key: 'ident'
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <Space size="middle">
                <ShowButton
                  recordItemId={record.id}
                  resource="quota"
                >
                  Open
                </ShowButton>
              </Space>
            ),
          }
        ]}
      />

      {/*<Title level={4}>Links</Title>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Web: </strong> {record?.web || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Linkedin: </strong> {record?.linkedin || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Twitter: </strong> {record?.twitter || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Instagram: </strong> {record?.instagram || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Youtube: </strong> {record?.youtube || "-"}*/}
      {/*</Paragraph>*/}
      {/*<Paragraph>*/}
      {/*  <strong>Github: </strong> {record?.github || "-"}*/}
      {/*</Paragraph>*/}
    </Show>
  );
};
