import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import {
  DateField,
  EditButton,
  FilterDropdown,
  getDefaultSortOrder,
  List,
} from "@refinedev/antd";
import {
  Select,
  Table,
  Button,
} from "antd";
import { IDiscountModel } from "../models/DiscountModel";
import { ShortId } from "../../../components/ShortId";
import useTableWithSearch from "../../../hooks/useTableWithSearch";

export const DiscountList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableData: { tableProps, sorter },
    getColumn,
    clear,
  } = useTableWithSearch<IDiscountModel>({
    resource: "discount",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filterFields: ["name", "type", "code"],
  });

  return (
    <List
      title="Discount"
      headerButtons={({ defaultButtons }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table<IDiscountModel> {...tableProps} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={ShortId}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select ID"
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          {...getColumn("name")}
          render={(value, record: IDiscountModel) => (
            <EditButton
              type="link"
              recordItemId={record.id}
              resource="discount"
            >
              {value}
            </EditButton>
          )}
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title="Type"
          {...getColumn("type")}
        />
        <Table.Column
          dataIndex="code"
          key="code"
          title="Code"
          {...getColumn("code")}
        />
        <Table.Column
          dataIndex="value"
          key="value"
          title="Value"
          // render={value => numberToPrice(value)}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => (
            <DateField format="DD.MM.YY HH:mm:ss" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="updatedAt"
          render={(value) => (
            <DateField format="DD.MM.YY HH:mm:ss" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
      </Table>
    </List>
  );
};
