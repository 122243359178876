import {
  Table,
  Tag,
  Select,
} from "antd";
import {
  DateField,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import React, { useMemo } from "react";
import { CrudFilter } from "@refinedev/core";
import {
  UserOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  CheckOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { IOrderModel, OrderStatus, OrderStatuses, OrderTypes } from "../pages/Billing/models/OrderModel";
import { numberToPrice } from "../utils";
import TableTitleWithCount from "./TableTitleWithCount";

const orderStatusIcon = (status: OrderStatus) => {
  switch (status) {
    case 'created': return <PlusCircleOutlined />;
    case 'pending': return <LoadingOutlined />;
    case 'success': return <CheckOutlined />;
    case 'canceled': return <UserOutlined />;
    case 'fail': return <CloseCircleOutlined />;
  }
  return null;
}

const orderStatusColor = (status: OrderStatus) => {
  switch (status) {
    case 'created': return 'gray';
    case 'pending': return 'blue';
    case 'success': return 'green';
    case 'canceled':
    case 'fail':
      return 'red';
  }
}

type OrderListProps = {
  teamId?: string
  userId?: string
  discountId?: string
  canCreate?: boolean
  listProps?: object
}

const OrderList: React.FC<OrderListProps> = ({
  teamId,
  userId,
  discountId,
  canCreate = false,
  listProps,
}) => {
  const filter: CrudFilter[] = useMemo(() => {
    const filter: CrudFilter[] = [];
    if (teamId) {
      filter.push({
        field: 'teamId',
        operator: 'eq',
        value: teamId
      })
    }
    if (userId) {
      filter.push({
        field: 'userId',
        operator: 'eq',
        value: userId
      })
    }
    if (discountId) {
      filter.push({
        field: 'discountId',
        operator: 'eq',
        value: discountId
      })
    }
    return filter;
  }, [discountId, teamId, userId]);

  const { tableProps, sorter, tableQueryResult } = useTable<IOrderModel>({
    resource: 'order',
    initialFilter: filter,
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialPageSize: 50
  });

  return (
    <List
      title={<TableTitleWithCount title="Orders" tableQueryResult={tableQueryResult} />}
      canCreate={canCreate}
      {...listProps}
    >
      <Table<IOrderModel>
        {...tableProps}
        size="small"
        rowKey="id"
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => (
            <ShowButton
              type="link"
              recordItemId={value}
              resource="order"
            >
              {value}
            </ShowButton>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select ID"
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          render={(value, record: IOrderModel) => (
            <Tag color={orderStatusColor(record.status)}>
              {orderStatusIcon(record.status)}
              {' ' + value}
            </Tag>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select Statuses"
              >
                {OrderStatuses.map(status => (
                  <Select.Option key={status} value={status}>{status}</Select.Option>
                ))}
              </Select>
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title="Type"
          render={value => (
            <Tag color={value === 'scheduled' ? 'blue' : 'green'}>
              { value === 'scheduled' ? <ReloadOutlined/> : <PlusCircleOutlined/> } {value}
            </Tag>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select Types"
              >
                {OrderTypes.map(type => (
                  <Select.Option key={type} value={type}>{type}</Select.Option>
                ))}
              </Select>
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex={['team','subdomain']}
          key="teamId"
          title="Team"
          render={(value, record: IOrderModel) => (
            <ShowButton
              type="link"
              icon={<TeamOutlined />}
              recordItemId={record?.teamId}
              resource="teams"
            >
              {value}
            </ShowButton>
          )}
        />
        <Table.Column
          dataIndex={['tariff','name']}
          key="tariffId"
          title="Tariff"
          render={(value, record: IOrderModel) => (
            <ShowButton
              type="link"
              recordItemId={record?.tariffId}
              resource="tariff"
            >
              {value}
            </ShowButton>
          )}
        />
        <Table.Column
          dataIndex="price"
          key="price"
          title="Price"
          render={value => numberToPrice(value)}
        />
        <Table.Column
          dataIndex="cost"
          key="cost"
          title="Cost"
          render={value => numberToPrice(value)}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => <DateField format="DD.MM.YY HH:mm:ss" value={value} />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="updatedAt"
          render={(value) => <DateField format="DD.MM.YY HH:mm:ss" value={value} />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
      </Table>
    </List>
  );
}

export default OrderList;
