import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, Edit, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import { IDiscountModel } from "../models/DiscountModel";

export const DiscountCreate: React.FC<IResourceComponentsProps> = () => {
  const {formProps, saveButtonProps} = useForm<IDiscountModel>();

  const CreateOrEdit = formProps.id ? Edit : Create;

  return (
    <CreateOrEdit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="type"
          name="type"
          rules={[
            {
              required: true,
              type: 'string'
            },
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="code"
          name="code"
          rules={[
            {
              type: 'string'
            },
          ]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="value"
          name="value"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input/>
        </Form.Item>
      </Form>
    </CreateOrEdit>
  );
};
