import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
} from "@refinedev/core";
import * as dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import BillingMetricsTable from "../../../components/BillingMetricsTable";
import { Button, DatePicker, Select, Space } from "antd";
import { ListProps } from "@refinedev/antd/dist/components/crud/types";
import Popconfirm from "antd/lib/popconfirm";

export type MetricsListProps = IResourceComponentsProps & {
  canCreate?: boolean;
  listProps?: Partial<ListProps>;
};

export const MetricsList: React.FC<MetricsListProps> = ({
  canCreate = true,
  listProps,
}) => {
  const [period, setPeriod] = useState<Date[]>([]);
  const [periodMacros, setPeriodMacros] = useState("currentMonth");
  const apiUrl = useApiUrl();

  const { refetch: initMonthMetrics, isFetching: isInitingMonth } = useCustom({
    url: apiUrl + "/billing_metrics/sync",
    method: "post",
    queryOptions: {
      enabled: false,
      retry: false,
    },
  });

  useEffect(() => {
    if (periodMacros === "other") {
      return;
    }
    let period = [];
    switch (periodMacros) {
      case "currentDay":
        period = [dayjs().startOf("day"), dayjs().endOf("day")];
        break;
      case "prevDay":
        period = [
          dayjs().subtract(1, "day").startOf("day"),
          dayjs().subtract(1, "day").endOf("day"),
        ];
        break;
      case "currentMonth":
        period = [dayjs().startOf("month"), dayjs().endOf("month")];
        break;
      case "prevMonth":
        period = [
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ];
        break;
      case "currentYear":
        period = [dayjs().startOf("year"), dayjs().endOf("year")];
        break;
      case "prevYear":
        period = [
          dayjs().subtract(1, "year").startOf("year"),
          dayjs().subtract(1, "year").endOf("year"),
        ];
        break;
    }

    if (period.length) {
      setPeriod(period);
    }
  }, [periodMacros]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Space.Compact>
          <DatePicker.RangePicker
            value={[dayjs(period[0]), dayjs(period[1])]}
            showTime
            allowClear={true}
            onChange={(values) => {
              setPeriod([values[0].toDate(), values[1].toDate()]);
              setPeriodMacros("other");
            }}
          />
          <Select
            style={{ width: "150px" }}
            value={periodMacros}
            onChange={(value) => setPeriodMacros(value)}
            options={[
              { label: "Сегодня", value: "currentDay" },
              { label: "Вчера", value: "prevDay" },
              { label: "Текущий месяц", value: "currentMonth" },
              { label: "Прошлый месяц", value: "prevMonth" },
              { label: "Текущий год", value: "currentYear" },
              { label: "Прошлый год", value: "prevYear" },
              { label: "Другое", value: "other" },
            ]}
          />
        </Space.Compact>
        <div>
          <Popconfirm
            title="Операция без проверок!"
            description="Сейчас будут созданы записи метрик по всем командам. Уверен?"
            onConfirm={() => initMonthMetrics()}
            okText="Да! Погнали!"
            cancelText="Отбой"
          >
            <Button danger loading={isInitingMonth}>
              Синхронизировать все команды
            </Button>
          </Popconfirm>
        </div>
      </div>
      <BillingMetricsTable
        startDate={period[0]}
        endDate={period[1]}
        listProps={{
          headerProps: {
            breadcrumb: undefined,
          },
        }}
      />
    </div>
  );
};
