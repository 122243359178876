import { Transfer } from 'antd';
import React, { useMemo } from 'react';

const TransferActions: React.FC<{
  availableActions: string[],
  value?: string[],
  onChange?: (items: string[]) => void,
}> = ({
  availableActions,
  value,
  onChange,
}) => {
  const actions = useMemo(() => {
    return !availableActions ? [] : availableActions.map(action => ({
      key: action,
      title: action,
    }))
  }, [availableActions])

  return (
    <Transfer
      dataSource={actions}
      titles={['Source', 'Target']}
      targetKeys={value}
      onChange={keys => onChange && onChange(keys)}
      render={item => item.title}
      oneWay
      style={{ marginBottom: 16 }}
      pagination={{ pageSize:100 }}
    />
  );
};

export default TransferActions;
