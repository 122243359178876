import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  List,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  FilterDropdown,
  getDefaultSortOrder,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Button,
  Select,
} from "antd";
import { IQuotaModel } from "../models/QuotaModel";
import { ShortId } from "../../../components/ShortId";
import useTableWithSearch from "../../../hooks/useTableWithSearch";

export const QuotaList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableData: { tableProps, sorter },
    getColumn,
    clear,
  } = useTableWithSearch<IQuotaModel>({
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filterFields: ["name", "ident", "value"],
  });

  return (
    <List
      headerButtons={({ defaultButtons }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table {...tableProps} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={ShortId(value)} />}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select ID"
              />
            </FilterDropdown>
          )}
        />
        <Table.Column<IQuotaModel>
          dataIndex="name"
          key="name"
          title="Name"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          {...getColumn("name")}
        />
        <Table.Column<IQuotaModel>
          dataIndex="ident"
          key="ident"
          title="IDENT"
          defaultSortOrder={getDefaultSortOrder("ident", sorter)}
          sorter
          {...getColumn("ident")}
        />
        <Table.Column<IQuotaModel>
          dataIndex="value"
          key="value"
          title="Value"
          defaultSortOrder={getDefaultSortOrder("value", sorter)}
          sorter
          {...getColumn("value")}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => <DateField value={value} />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="updatedAt"
          render={(value) => <DateField value={value} />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<IQuotaModel>
          title="Actions"
          dataIndex="actions"
          key="actions"
          render={(_text, record) => {
            return (
              <Space>
                <EditButton recordItemId={record.id} size="small" />
                <DeleteButton recordItemId={record.id} size="small" />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
