import {AccessControl} from "accesscontrol";
import {AppRoles} from "../../ac";
import { FileOperations } from './fileOperations';
import { TeamList, TeamShow } from "./team";
import { ModuleInterface } from "@interfaces";
import { DefaultCrudCreate, DefaultCrudEdit } from "../../components/DefaultCrud";
import UsersModel from "../../models/UsersModel";
import { UserShow, UsersList } from "./users";
import { Route } from "react-router-dom";

const YonoteModule: ModuleInterface = {
  accessControl: (ac: AccessControl) => {
    ac.grant(AppRoles.ADMIN)
      .read(['team', 'user', 'file_operations'])
      .create(['team', 'user', 'file_operations'])
      .update(['team', 'user', 'file_operations'])
      .delete(['team', 'user', 'file_operations']);

    return ac;
  },
  resources: [
    {
      name: 'teams',
      options: { label: 'Team' },
      list: TeamList,
      show: TeamShow,
    },
    {
      name: "users",
      list: UsersList,
      create: (props) => (
        <DefaultCrudCreate model={UsersModel} {...props} />
      ),
      edit: (props) => <DefaultCrudEdit model={UsersModel} {...props} />,
      show: UserShow,
    },
    {
      name: 'file_operations',
      options: { label: 'FileOperations' },
      list: FileOperations,
      show: TeamShow,
    },
  ],
  routes: [
    <Route key={'teams'} path="/teams">
      <Route index element={ <TeamList/> }/>
      <Route path="show/:id" element={ <TeamShow/> }/>
    </Route>,
    <Route key={'users'} path="/users">
      <Route index element={ <UsersList/> }/>
      <Route path="show/:id" element={ <UserShow/> }/>
    </Route>,
    <Route key={'file_operations'} path="/file_operations">
      <Route index element={ <FileOperations/> }/>
      <Route path="show/:id" element={ <UserShow/> }/>
    </Route>,
  ]
};

export default YonoteModule;
