import { IOrderModel } from "./OrderModel";

export const TransactionTypes = ["income", "refund"] as const;
export type TransactionType = typeof TransactionTypes[number];

export const TransactionStatuses = [
  "init",
  "pending",
  "complete",
  "canceledByUser",
  "canceledByGateway",
  "canceledByAdmin",
] as const;
export type TransactionStatus = typeof TransactionStatuses[number];

export const PaymentGateways = [
  "Tinkoff",
  "YooKassa",
  "BankAccount",
  "Manual",
] as const;
export type PaymentGateway = typeof PaymentGateways[number];

export interface ITransactionModel {
  id: string;
  trxId: string;
  paymentGateway: PaymentGateway;
  orderId: number;
  type: TransactionType;
  status: TransactionStatus;
  value: number;
  meta: any;
  createdAt: Date;

  order: IOrderModel;
}
