import { useEffect, useState } from "react";

function useDebouncedState<Value = unknown>(value: Value, delay = 500): Value {
  const [debouncedValue, setDebouncedValue] = useState<Value>(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(handler);
    },
    [value, delay]
  );

  return debouncedValue;
}

export default useDebouncedState;
