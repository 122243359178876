
export const numberToPrice = (value: number, stripDivision = false) => {
  if (value === 0) {
    return '0';
  }
  const str = value + '';
  return str.slice(0, -2) + (!stripDivision ? '.' + str.slice(-2) : '')
}

export const numberToK = (n: number, postfix: string = '') => {
  if (n < 1e3) return n + postfix;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K" + postfix;
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M" + postfix;
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "G" + postfix;
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T" + postfix;
  return n + postfix;
}
