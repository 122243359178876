import { ShowButton } from "@refinedev/antd";
import { Timeline } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import React from "react";
import { IUsersModel } from "../models/UsersModel";

type UserStagesTimelineProps = {
  user: IUsersModel
}

const UserStagesTimeline: React.FC<UserStagesTimelineProps> = ({ user }) => {
  return (
    <Timeline>
      <Timeline.Item color="green">
        {
          user.invitedById ? (
            <div>
              Приглашен
              <ShowButton
                type="link"
                icon={<UserOutlined />}
                recordItemId={user.invitedById}
                resource="users"
              >
                пользователем
              </ShowButton>
            </div>
          ) : (
            <div>Создан</div>
          )
        }
        <div>{moment(user.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
      </Timeline.Item>
      <Timeline.Item color="gray">
        <div>Обновлен</div>
        <div>{moment(user.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</div>
      </Timeline.Item>
      <Timeline.Item color="blue">
        <div>Авторизовался</div>
        <div>{moment(user.lastSignedInAt).format('DD.MM.YYYY HH:mm:ss')}</div>
      </Timeline.Item>
      <Timeline.Item color="gray">
        <div>Последняя активность</div>
        <div>{moment(user.lastActiveAt).format('DD.MM.YYYY HH:mm:ss')}</div>
        <div>IP: {user.lastActiveIp}</div>
      </Timeline.Item>
      {
        user.suspendedAt && (
          <Timeline.Item color="red">
            <div>
              Отключен
              <ShowButton
                type="link"
                icon={<UserOutlined />}
                recordItemId={user.suspendedById}
                resource="users"
              >
                пользователем
              </ShowButton>
            </div>
            <div>{moment(user.suspendedAt).format('DD.MM.YYYY HH:mm:ss')}</div>
          </Timeline.Item>
        )
      }
      {
        user.deletedAt && (
          <Timeline.Item color="red">
            <div>Удален</div>
            <div>{moment(user.deletedAt).format('DD.MM.YYYY HH:mm:ss')}</div>
          </Timeline.Item>
        )
      }
    </Timeline>
  )
}

export default UserStagesTimeline
