import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import UserList from "../../../components/UserList";

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  return (
    <UserList
      listProps={{ canCreate: false }}
    />
  )
};
