import Keycloak from "keycloak-js";

const getEnv = (env: string) => process.env[env] || window[env] || '';

export const API_URL = getEnv('REACT_APP_API_URL') || window.location.origin;
export const FREESCOUT_URL = getEnv('REACT_APP_FREESCOUT_URL') || '';
export const REACT_APP_FREESCOUT_API_KEY = getEnv('REACT_APP_FREESCOUT_API_KEY') || '';

export const FREESCOUT_ENABLED = !!(FREESCOUT_URL && REACT_APP_FREESCOUT_API_KEY);

export const keycloak = new Keycloak({
  url: getEnv('REACT_APP_KEYCLOAK_URL'),
  realm: getEnv('REACT_APP_AUTH_REALM'),
  clientId: getEnv('REACT_APP_AUTH_CLIENT_ID'),
});
