import { DateField, getDefaultSortOrder, List, ShowButton } from "@refinedev/antd";
import { Button, Input, Table } from "antd";
import { ShortId } from "./ShortId";
import React, { useMemo } from "react";
import { IEventModel } from "../models/EventsModel";
import { CrudFilter } from "@refinedev/core";
import { ReloadOutlined, TeamOutlined } from '@ant-design/icons';
import TableTitleWithCount from "./TableTitleWithCount";
import useTableWithSearch from "../hooks/useTableWithSearch";

type BillingMetricsTotalListProps = {
  teamId?: string
}

const BillingMetricsTotalList: React.FC<BillingMetricsTotalListProps> = ({
  teamId,
}) => {
  const filter: CrudFilter[] = useMemo(() => {
    const filter: CrudFilter[] = [];
    if (teamId) {
      filter.push({
        field: 'teamId',
        operator: 'eq',
        value: teamId
      })
    }
    return filter;
  }, [teamId]);

  const {
    tableData: {
      tableProps,
      sorter,
      tableQueryResult,
    },
    getColumn,
    clear,
  } = useTableWithSearch<IEventModel>({
    resource: 'billing_metrics_total',
    initialFilter: filter,
  });

  return (
    <List
      title={<TableTitleWithCount title="Events" tableQueryResult={tableQueryResult} />}
      canCreate={false}
      headerProps={{ breadcrumb: undefined }}
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<ReloadOutlined />}
            loading={tableQueryResult.isLoading}
            onClick={tableQueryResult.refetch}
          />
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table<IEventModel>
        {...tableProps}
        size="small"
        rowKey="id"
        loading={tableQueryResult.isLoading}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={ShortId}
        />
        <Table.Column
          dataIndex={['team','subdomain']}
          key="teamId"
          title="Team"
          {...getColumn("team.subdomain")}
          render={(value, record: IEventModel) => (
            <ShowButton
              type="link"
              icon={<TeamOutlined />}
              recordItemId={record?.teamId}
              resource="teams"
            >
              {value}
            </ShowButton>
          )}
          filterDropdown={(props) => (
            <Input
              name="filter-dropdown-input"
              size="middle"
            />
          )}
        />
        <Table.Column
          dataIndex="unit"
          key="unit"
          title="Unit"
        />
        <Table.Column
          dataIndex="value"
          key="value"
          title="Value"
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => <DateField format="DD.MM.YY HH:mm:ss" value={value} />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="updatedAt"
          render={(value) => <DateField format="DD.MM.YY HH:mm:ss" value={value} />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
      </Table>
    </List>
  );
}

export default BillingMetricsTotalList;
