import { useApiUrl, useCustom } from "@refinedev/core";
import { Button, Space } from "antd";
import React from "react";
import { IOrderModel } from "../../models/OrderModel";

export type OrderOperationsProps = {
  order: IOrderModel;
  refreshOrder: () => void;
};

const OrderOperations: React.FC<OrderOperationsProps> = ({
  order,
  refreshOrder,
}) => {
  const apiUrl = useApiUrl();

  const { refetch: markPaid, isFetching: isMarkingPaid } = useCustom({
    method: "post",
    url: `${apiUrl}/order/closeOrder`,
    config: {
      payload: {
        orderId: order.id,
      },
    },
    queryOptions: {
      enabled: false,
      retry: false,
      cacheTime: -1,
    },
  });

  return (
    <div style={{ textAlign: "right" }}>
      <Space.Compact>
        <Button
          type="dashed"
          target="_blank"
          href={apiUrl + "/order/showInvoice/" + order.id + "?tpl=invoice"}
        >
          Посмотреть счет
        </Button>
        <Button
          type="dashed"
          target="_blank"
          href={apiUrl + "/order/showInvoice/" + order.id + "?tpl=act"}
        >
          Посмотреть акт
        </Button>
        {order.status !== "success" ? (
          <Button
            danger
            loading={isMarkingPaid}
            onClick={() => markPaid().then(() => refreshOrder())}
          >
            Оплачен
          </Button>
        ) : null}
      </Space.Compact>
    </div>
  );
};

export default OrderOperations;
