import { useShow, IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import { Show, ShowButton } from "@refinedev/antd";
import { Button, Col, Row, Typography } from "antd";
import React, { useCallback, useRef } from "react";
import { IOrderModel } from "../models/OrderModel";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { numberToPrice } from "../../../utils";
import TransactionList from "../../../components/TransactionList";
import OrderOperations from "./components/OrderOperations";

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const trxRef = useRef(null);
  const { queryResult } = useShow<IOrderModel>();
  const { data, isLoading, refetch } = queryResult;
  const record = data?.data;

  const refresh = useCallback(() => {
    refetch();
    if (trxRef.current) {
      trxRef.current.refresh();
    }
  }, [refetch]);

  if (!record) {
    return <Show isLoading={isLoading} />;
  }

  return (
    <Show isLoading={isLoading}>
      <Row gutter={16}>
        <Col span={6}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            Order: {record?.id}
          </Typography.Title>
          <h4>
            Team:{" "}
            <ShowButton
              type="link"
              icon={<TeamOutlined />}
              recordItemId={record?.teamId}
              resource="teams"
            >
              {record?.team?.subdomain}
            </ShowButton>
          </h4>
          <h4>
            User:{" "}
            <ShowButton
              type="link"
              icon={<UserOutlined />}
              recordItemId={record?.userId}
              resource="users"
            >
              {record?.user.email}
            </ShowButton>
          </h4>
          <h4>
            Tariff:{" "}
            <ShowButton
              type="link"
              icon={<UserOutlined />}
              recordItemId={record?.tariffId}
              resource="tariff"
            >
              {record?.tariff.name}
            </ShowButton>
          </h4>
          <h4>
            Discount:{" "}
            {record?.discountId && (
              <ShowButton
                type="link"
                icon={<UserOutlined />}
                recordItemId={record?.discountId}
                resource="discount"
              >
                {record?.discount?.name}
              </ShowButton>
            )}
          </h4>
          <p>
            Price: {numberToPrice(record?.price)}
            <br />
            Cost: {numberToPrice(record?.cost)}
          </p>
          <h5>Description</h5>
          <p>{record.description}</p>
        </Col>
        <Col span={18}>
          <OrderOperations order={record} refreshOrder={refresh} />
          <TransactionList ref={trxRef} orderId={record.id} />
        </Col>
      </Row>
    </Show>
  );
};
