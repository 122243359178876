import { ITeamModel } from "../pages/Yonote/models/TeamModel";
import { IEventModel } from "./EventsModel";

export type MemberTypes = 'guest' | 'member' | 'admin' | 'owner';

export interface IUsersModel {
  id: string
  teamId: string
  username: string
  email: string
  name: string
  isAdmin: boolean
  isViewer: boolean
  isOwner: boolean
  avatarUrl: string
  flags: string
  serviceId: string
  federationId: string
  service: string
  language: string
  lastSigninEmailSentAt: Date
  lastActiveAt: Date
  lastSignedInAt: Date
  lastActiveIp: string
  lastSignedInIp: string
  invitedById: string
  suspendedById: string
  suspendedAt: Date
  createdAt: Date
  updatedAt: Date
  deletedAt: Date

  team?: ITeamModel
  events?: IEventModel[]
}

const UsersModel = {
  createdAt: {
    type: 'Date',
  },
  deletedAt: {
    type: 'Date',
  },
  lastSigninEmailSentAt: {
    type: 'Date',
  },
  updatedAt: {
    type: 'Date',
  },
  isViewer: {
    type: 'boolean',
  },
  flags: {
    type: 'string',
  },
  invitedById: {
    type: 'string',
  },
  teamId: {
    type: 'string',
  },
  isAdmin: {
    type: 'boolean',
  },
  isOwner: {
    type: 'boolean',
  },
  suspendedById: {
    type: 'string',
  },
  suspendedAt: {
    type: 'Date',
  },
  id: {
    type: 'string',
  },
  lastActiveAt: {
    type: 'Date',
  },
  lastSignedInAt: {
    type: 'Date',
  },
  email: {
    type: 'string',
  },
  username: {
    type: 'string',
  },
  name: {
    type: 'string',
  },
  serviceId: {
    type: 'string',
  },
  federationId: {
    type: 'string',
  },
  avatarUrl: {
    type: 'string',
  },
  service: {
    type: 'string',
  },
  lastActiveIp: {
    type: 'string',
  },
  lastSignedInIp: {
    type: 'string',
  },
  language: {
    type: 'string',
  },

}
export default UsersModel
