import { SettingOutlined } from '@ant-design/icons';
import {AccessControl} from "accesscontrol";
import React from 'react';
import {AppRoles} from "../../ac";
import RuntimeSettings from './runtimeSettings';
import { ModuleInterface } from "@interfaces";
import { Route } from "react-router-dom";

const RuntimeSettingsModule: ModuleInterface = {
  accessControl: (ac: AccessControl) => {
    ac.grant(AppRoles.ADMIN)
      .read(['runtime'])
      .create(['runtime'])
      .update(['runtime'])
      .delete(['runtime']);

    return ac;
  },
  resources: [
    {
      name: 'runtime',
      meta: { label: "Runtime", icon: <SettingOutlined /> },
      list: RuntimeSettings,
    },
  ],
  routes: [
    <Route key={'runtime'} path="/runtime">
      <Route index element={ <RuntimeSettings/> }/>
    </Route>,
  ]
};

export default RuntimeSettingsModule;
