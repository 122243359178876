import { Button, Col, Modal, Row, Switch, Typography } from "antd";
import { useModalForm } from "@refinedev/antd";
import TeamTable from "../../Yonote/team/components/TeamTable";
import React, { useState } from "react";
import useTableWithSearch from "../../../hooks/useTableWithSearch";
import { ITariffModel } from "../models/TariffModel";
import { ITeamModel } from "../../Yonote/models/TeamModel";
import { useCreate } from "@refinedev/core";

type TeamSelectionModalProps = {
  tariffId: string | undefined;
  show: boolean;
  setShow: (show: boolean) => void;
  onChange?: () => void;
};

const TeamSelectionModal = ({
  tariffId,
  show,
  setShow,
  onChange,
}: TeamSelectionModalProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const { modalProps: createModalProps } = useModalForm({
    action: "create",
  });
  const { mutate } = useCreate();

  //Получение команд которые не входят в данный тариф
  const {
    tableData: { tableProps, sorter },
    getColumn,
    clear,
  } = useTableWithSearch<ITariffModel>({
    resource: "teams",
    meta: {
      join: {
        field: "tariffToTeam",
      },
    },
    permanentFilter: [
      {
        operator: "or",
        value: [
          {
            field: "tariffToTeam.tariffToTeamId",
            operator: "eq",
            value: null,
          },
          {
            field: "tariffToTeam.tariffId",
            operator: "ne",
            value: tariffId,
          },
        ],
      },
      {
        operator: "or",
        value: [
          {
            field: "tariffToTeam.tariffToTeamId",
            operator: "eq",
            value: null,
          },
          {
            field: "tariffToTeam.isActive",
            operator: "eq",
            value: true,
          },
        ],
      },
    ],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filterFields: ["name", "subdomain", "domain"],
  });

  const onOk = () => {
    if (selectedTeams) {
      const request = selectedTeams.map((item) => {
        return {
          tariffId: tariffId,
          teamId: item,
          isActive: isActive,
          isDefault: isDefault,
        };
      });
      mutate({
        resource: "tariff_to_team/bulk",
        values: { bulk: request },
      });
      onChange && onChange();
    }
    setSelectedTeams([]);
    setShow(false);
  };

  const onSelect = (
    _record: Object,
    _selected: boolean,
    selectRowKey: ITeamModel[]
  ) => {
    const teams = selectRowKey.map((item) => item.id);
    setSelectedTeams(teams);
  };

  return (
    <Modal
      {...createModalProps}
      title="Transfer Team"
      onCancel={() => setShow(false)}
      okText="Save and Add"
      okButtonProps={{
        onClick: onOk,
      }}
      open={show}
      destroyOnClose
    >
      <Row
        gutter={[0, 16]}
        justify={"space-around"}
        style={{ marginBottom: "15px" }}
      >
        <Col span={9}>
          <Typography.Text>
            Make tariff <Typography.Text strong>isActive</Typography.Text> for
            selected teams?
          </Typography.Text>
          <Switch checked={isActive} onChange={() => setIsActive(!isActive)} />
        </Col>
        <Col span={9}>
          <Typography.Text>
            Make tariff <Typography.Text strong>isDefault</Typography.Text> for
            selected teams?
          </Typography.Text>
          <Switch
            checked={isDefault}
            onChange={() => setIsDefault(!isDefault)}
          />
        </Col>
      </Row>
      <Row gutter={[0, 16]} justify="end" style={{ marginBottom: "15px" }}>
        <Button onClick={clear}>Clear filters</Button>
      </Row>
      <TeamTable
        selectedRowKeys={selectedTeams}
        onSelect={onSelect}
        tableProps={tableProps}
        getColumn={getColumn}
        sorter={sorter}
      />
    </Modal>
  );
};
export default TeamSelectionModal;
