import { AccessControl } from "accesscontrol";

export enum AppRoles {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
}

// Объявление прав на ресурсы
// resource_name: bool -> Применяется ко всем операциям
// resource_name: [read, create, update, delete]
const permisstions: {
  [name: string]: { [resource: string]: boolean | boolean[] };
} = {
  [AppRoles.EDITOR]: {
    billing_metrics: [true, true],
  },
  [AppRoles.ADMIN]: {
    billing_metrics: true,
    transaction: true,

    groups: true,
    group_users: true,
    attachments: true,
    users: true,
    documents: true,
    collection_groups: true,
    search_queries: true,
    file_operations: true,
    pins: true,
    user_authentications: true,
    revisions: true,
    teams: true,
    database_views: true,
    collections: true,
    apiKeys: true,
    stars: true,
    authentications: true,
    integrations: true,
    team_domains: true,
    views: true,
    notifications: true,
    notification_settings: true,
    events: true,
    shares: true,
    collection_users: true,
    backlinks: true,
  },
};

const ac = new AccessControl();

let lastRole = "";
for (const [role, resources] of Object.entries(permisstions)) {
  let acc = ac.grant(role);
  if (lastRole) {
    acc.extend(lastRole);
  }

  for (const [resource, access] of Object.entries(resources)) {
    if (typeof access === "boolean" && access) {
      acc = acc
        .read(resource)
        .create(resource)
        .update(resource)
        .delete(resource);
    }
    if (Array.isArray(access)) {
      if (access[0]) {
        acc = acc.read(resource);
      }
      if (access[1]) {
        acc = acc.create(resource);
      }
      if (access[2]) {
        acc = acc.update(resource);
      }
      if (access[3]) {
        acc = acc.delete(resource);
      }
    }
  }

  lastRole = role;
}

export default ac;
