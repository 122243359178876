
export const EventNames = [
  "collections.create",
  "collections.delete",
  "collections.move",
  "collections.permission_changed",
  "documents.publish",
  "documents.unpublish",
  "documents.archive",
  "documents.unarchive",
  "documents.move",
  "documents.delete",
  "documents.permanent_delete",
  "documents.restore",
  "revisions.create",
  "users.create",
  "provider.create",
  "provider.update",
  "api_keys.create",
  "api_keys.delete",
  "authenticationProviders.update",
  "collections.create",
  "collections.update",
  "collections.permission_changed",
  "collections.move",
  "collections.add_user",
  "collections.remove_user",
  "collections.add_group",
  "collections.remove_group",
  "collections.delete",
  "documents.create",
  "documents.publish",
  "documents.update",
  "documents.archive",
  "documents.unarchive",
  "documents.move",
  "documents.delete",
  "documents.permanent_delete",
  "documents.restore",
  "groups.create",
  "groups.update",
  "groups.delete",
  "pins.create",
  "pins.update",
  "pins.delete",
  "revisions.create",
  "shares.create",
  "shares.update",
  "shares.revoke",
  "teams.update",
  "users.create",
  "users.update",
  "users.signin",
  "users.signout",
  "users.promote",
  "users.demote",
  "users.invite",
  "users.suspend",
  "users.activate",
  "users.delete",
  "webhook_subscriptions.create",
  "webhook_subscriptions.delete",
]

export interface IEventModel {
  id: string
  teamId: string
  userId: string
  collectionId: string
  documentId: string
  actorId: string
  modelId: string
  name: string
  ip: string
  data: object
  createdAt: Date
  updatedAt: Date
}

const EventsModel = {
  id: {
    type: 'string',
  },
  data: {
    type: 'string',
  },
  userId: {
    type: 'string',
  },
  collectionId: {
    type: 'string',
  },
  teamId: {
    type: 'string',
  },
  createdAt: {
    type: 'Date',
  },
  updatedAt: {
    type: 'Date',
  },
  documentId: {
    type: 'string',
  },
  actorId: {
    type: 'string',
  },
  modelId: {
    type: 'string',
  },
  name: {
    type: 'string',
  },
  ip: {
    type: 'string',
  },

}
export default EventsModel
