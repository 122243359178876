import { Table, Select, Typography, Button } from "antd";
import {
  DateField,
  DeleteButton,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  useTable,
} from "@refinedev/antd";
import React, { useImperativeHandle, useMemo } from "react";
import { CrudFilter } from "@refinedev/core";
import { EyeOutlined } from "@ant-design/icons";
import { numberToPrice } from "../utils";
import {
  ITransactionModel,
  TransactionStatuses,
  TransactionTypes,
} from "../pages/Billing/models/TransactionModel";
import { ShortId } from "./ShortId";
import useMetaViewer from "../hooks/useMetaViewer";

export type TransactionListRef = {};

export type TransactionListProps = {
  orderId?: number;
  trxId?: string;
};

const TransactionList = React.forwardRef<
  TransactionListRef,
  TransactionListProps
>(({ orderId, trxId }, ref) => {
  const { showMeta, metaComponent } = useMetaViewer();
  const filter: CrudFilter[] = useMemo(() => {
    const filter: CrudFilter[] = [];
    if (orderId) {
      filter.push({
        field: "orderId",
        operator: "eq",
        value: orderId,
      });
    }
    if (trxId) {
      filter.push({
        field: "trxId",
        operator: "eq",
        value: trxId,
      });
    }
    return filter;
  }, [orderId, trxId]);

  const { tableProps, sorter, tableQueryResult } = useTable<ITransactionModel>({
    resource: "transaction",
    initialFilter: filter,
    initialSorter: [
      {
        field: "createdAt",
        order: "asc",
      },
    ],
  });

  useImperativeHandle(ref, () => ({
    refresh: tableQueryResult.refetch,
  }));

  return (
    <List
      title="Transactions"
      canCreate={false}
      headerProps={{ breadcrumb: undefined }}
    >
      <Table<ITransactionModel>
        {...tableProps}
        summary={(data) => {
          let sum = 0;
          data.forEach((item) => (sum = sum + item.value));

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4} align="right">
                Balance
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <Typography.Text
                  type={sum < 0 ? "danger" : sum === 0 ? "success" : "warning"}
                >
                  {numberToPrice(sum)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} />
            </Table.Summary.Row>
          );
        }}
        size="small"
        rowKey="id"
      >
        <Table.Column dataIndex="id" key="id" title="ID" render={ShortId} />
        <Table.Column
          dataIndex="orderId"
          key="orderId"
          title="Order"
          render={(value) => (
            <ShowButton type="link" recordItemId={value} resource="order">
              {value}
            </ShowButton>
          )}
        />
        <Table.Column
          dataIndex="type"
          key="type"
          title="Type"
          align="right"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: "100%", minWidth: "200px" }}
                placeholder="Select Types"
              >
                {TransactionTypes.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: "100%", minWidth: "200px" }}
                placeholder="Select Statuses"
              >
                {TransactionStatuses.map((status) => (
                  <Select.Option key={status} value={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="value"
          key="value"
          title="Value"
          align="right"
          render={(value) => numberToPrice(value)}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => (
            <DateField format="DD.MM.YY HH:mm:ss" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          width={100}
          key="meta"
          title=""
          render={(_, record: any) => (
            <>
              {record.meta && (
                <Button
                  icon={<EyeOutlined />}
                  title="meta"
                  onClick={() => showMeta(record.meta)}
                  style={{ marginRight: "5px" }}
                />
              )}
              <DeleteButton
                resource="transaction"
                recordItemId={record.id}
                hideText
              />
            </>
          )}
        />
      </Table>
      {metaComponent}
    </List>
  );
});

export default TransactionList;
