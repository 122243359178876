
const YonoteLogo = (props: { width?: string, height?: string }) => (
  <svg
    fill="#333"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 351.5 85.04"
    style={{ width: props.width, height: props.height }}
    x="0px"
    y="0px"
  >
    <g>
      <polygon className="cls-1"
               points="134.85 16.77 121.78 39.7 108.61 16.77 100.53 16.77 118.28 46.51 118.28 68.3 125.22 68.3 125.22 46.47 142.97 16.77 134.85 16.77"></polygon>
      <path className="cls-1"
            d="M161.58,27c-11.38,0-20.65,9.5-20.65,21.18a20.62,20.62,0,1,0,41.22,0C182.15,36.51,172.92,27,161.58,27Zm0,35.86c-7.85,0-14-6.47-14-14.71s6.27-14.75,14-14.75S175.53,40,175.53,48.16,169.4,62.87,161.58,62.87Z"></path>
      <polygon className="cls-1"
               points="226.6 68.3 226.6 27.76 219.84 27.76 219.84 57.42 196.58 27.76 189.87 27.76 189.87 68.3 196.6 68.3 196.6 37.94 220.14 68.3 226.6 68.3"></polygon>
      <path className="cls-1"
            d="M255,27c-11.38,0-20.64,9.5-20.64,21.18s9.23,21.08,20.57,21.08a20.9,20.9,0,0,0,20.68-21.08C275.57,36.51,266.33,27,255,27Zm0,35.86c-7.87,0-14-6.47-14-14.71s6.29-14.75,14-14.75S268.92,40,268.92,48.16,262.67,62.87,255,62.87Z"></path>
      <polygon className="cls-1"
               points="313.41 27.76 279.92 27.76 279.92 34.17 293.25 34.17 293.25 68.3 300.01 68.3 300.01 34.17 313.41 34.17 313.41 27.76"></polygon>
      <polygon className="cls-1"
               points="346.72 50.8 346.72 44.54 327.85 44.54 327.85 34.13 351.06 34.13 351.06 27.76 321.13 27.76 321.13 68.3 351.5 68.3 351.5 61.93 327.85 61.93 327.85 50.8 346.72 50.8"></polygon>
      <path className="cls-1"
            d="M80.19,0H21.59a4.86,4.86,0,0,0-4.85,4.85V8.37H13.22a4.86,4.86,0,0,0-4.85,4.85v3.52H4.85A4.86,4.86,0,0,0,0,21.59v55L50.44,85a4.25,4.25,0,0,0,.8.07h12.2a4.86,4.86,0,0,0,4.86-4.85V76.67h3.52a4.86,4.86,0,0,0,4.85-4.85V68.3h3.52A4.86,4.86,0,0,0,85,63.45V4.85A4.86,4.86,0,0,0,80.19,0ZM12.45,67.14a2.12,2.12,0,0,1,1.5-.62,2.06,2.06,0,0,1,1,.27L37.68,79.4,5.55,74Zm4.18-3.33a5.58,5.58,0,0,0-2.68-.7,5.49,5.49,0,0,0-3.9,1.62L3.4,71.37V21.59a1.45,1.45,0,0,1,1.45-1.45H63.44a1.45,1.45,0,0,1,1.45,1.45v58.6a1.45,1.45,0,0,1-1.45,1.45H51.24l-.24,0-3.31-.56ZM63.44,16.74H11.77V13.22a1.45,1.45,0,0,1,1.45-1.45h58.6a1.46,1.46,0,0,1,1.45,1.45v58.6a1.46,1.46,0,0,1-1.45,1.45H68.3V21.59A4.86,4.86,0,0,0,63.44,16.74Zm8.38-8.37H20.14V4.85A1.45,1.45,0,0,1,21.59,3.4h58.6a1.46,1.46,0,0,1,1.45,1.45v58.6a1.46,1.46,0,0,1-1.45,1.44H76.67V13.22A4.86,4.86,0,0,0,71.82,8.37Z"></path>
      <polygon className="cls-1"
               points="31.43 28.07 23.66 41.71 15.82 28.07 10.21 28.07 21.23 46.53 21.23 59.99 26.04 59.99 26.04 46.5 37.06 28.07 31.43 28.07"></polygon>
      <path className="cls-1"
            d="M45.28,34.29A13.15,13.15,0,1,0,58.09,47.47,13,13,0,0,0,45.28,34.29Zm0,21.81a8.35,8.35,0,0,1-8.22-8.65,8.47,8.47,0,0,1,8.22-8.68,8.46,8.46,0,0,1,8.19,8.68A8.34,8.34,0,0,1,45.28,56.1Z"></path>
    </g>
  </svg>
)

export default YonoteLogo;
