import { Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { ITeamModel } from "../../models/TeamModel";
import UserList from "../../../../components/UserList";
import { TariffTable } from "../../../../components/TariffTable";
import { TariffSelect } from "../../../../components/TariffSelect";
import StatisticTeam from "../components/Statistics";
import OrderList from "../../../../components/OrderList";
import { PaymentMethodList } from "../../../Billing/payment-method";

const { Title } = Typography;

type TeamShowMainProps = {
  team: ITeamModel;
};

export const TeamShowMain: React.FC<TeamShowMainProps> = ({ team }) => {
  const [updateTableTariff, setUpdateTableTariff] = useState<() => void>();

  return (
    <>
      <Row justify="space-between">
        <Col span={13}>
          <StatisticTeam teamId={team.id as string} />
        </Col>
        <Col span={10}>
          <Title level={5}>Tariff</Title>
          <TariffSelect
            style={{ width: "100%", marginBottom: "15px" }}
            updateTableTariff={updateTableTariff}
            teamId={team.id as string}
          />
          <TariffTable
            teamId={team.id as string}
            setUpdateTableTariff={setUpdateTableTariff}
          />
        </Col>
      </Row>

      <PaymentMethodList
        teamId={team.id as string}
        listProps={{
          headerProps: {
            breadcrumb: undefined,
          },
        }}
        canCreate={true}
      />

      <OrderList
        teamId={team.id as string}
        listProps={{ pageHeaderProps: { breadcrumb: undefined } }}
      />

      <UserList
        teamId={team.id as string}
        listProps={{ pageHeaderProps: { breadcrumb: undefined } }}
      />
    </>
  );
};
