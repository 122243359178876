import { DownOutlined } from '@ant-design/icons';
import { useApiUrl, useCustom } from '@refinedev/core';
import { Button, DatePicker, Dropdown, MenuProps, Popconfirm, Select, Space, Typography } from 'antd';
import * as dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import BillingMetricsTable from '../../../../components/BillingMetricsTable';
import { ITeamModel } from '../../models/TeamModel';

export type BillingMetricsProps = {
  team: ITeamModel;
};

const BillingMetrics: React.FC<BillingMetricsProps> = ({ team }) => {
  const [period, setPeriod] = useState<Date[]>([]);
  const [periodMacros, setPeriodMacros] = useState('currentMonth');
  const apiUrl = useApiUrl();

  const previewUrl = useMemo(() => {
    return `${ apiUrl }/billing_metrics/renderDocument/${ team.id }?startAt=${ period[0] }&endAt=${ period[1] }`;
  }, [apiUrl, period, team.id]);

  const previewInvoiceFormats = useMemo(() => [
    {
      label: <a href={ previewUrl + '&type=invoice&format=html' } target="_blank">HTML</a>,
      key: '0',
    },
    {
      label: <a href={ previewUrl + '&type=invoice&format=pdf' } target="_blank">PDF</a>,
      key: '1',
    },
  ] as MenuProps['items'], [previewUrl]);

  const previewActFormats = useMemo(() => [
    {
      label: <a href={ previewUrl + '&type=act&format=html' } target="_blank">HTML</a>,
      key: '0',
    },
    {
      label: <a href={ previewUrl + '&type=act&format=pdf' } target="_blank">PDF</a>,
      key: '1',
    },
  ] as MenuProps['items'], [previewUrl]);

  const {
    refetch: makeInvoice,
    isFetching: isMakingInvoice,
    remove: clearInvoice,
  } = useCustom({
    url:
      apiUrl +
      '/billing_metrics/makeInvoice/' +
      team.id +
      '?' +
      getQuery(period),
    method: 'post',
    queryOptions: {
      enabled: false,
      retry: false,
    },
  });

  const {
    data: metrics,
    refetch: calcMetrics,
    isFetching: isCalcLoading,
    remove,
  } = useCustom({
    url:
      apiUrl + '/billing_metrics/calculate/' + team.id + '?' + getQuery(period),
    method: 'post',
    queryOptions: {
      enabled: false,
      retry: false,
    },
  });

  const { refetch: syncTeam, isFetching: isSyncingTeam } = useCustom({
    url: apiUrl + '/billing_metrics/sync/' + team.id,
    method: 'post',
    queryOptions: {
      enabled: false,
      retry: false,
    },
  });

  useEffect(() => {
    if (periodMacros === 'other') {
      return;
    }
    let period = [];
    switch (periodMacros) {
      case 'currentDay':
        period = [dayjs().startOf('day'), dayjs().endOf('day')];
        break;
      case 'prevDay':
        period = [
          dayjs().subtract(1, 'day').startOf('day'),
          dayjs().subtract(1, 'day').endOf('day'),
        ];
        break;
      case 'currentMonth':
        period = [dayjs().startOf('month'), dayjs().endOf('month')];
        break;
      case 'prevMonth':
        period = [
          dayjs().subtract(1, 'month').startOf('month'),
          dayjs().subtract(1, 'month').endOf('month'),
        ];
        break;
      case 'currentYear':
        period = [dayjs().startOf('year'), dayjs().endOf('year')];
        break;
      case 'prevYear':
        period = [
          dayjs().subtract(1, 'year').startOf('year'),
          dayjs().subtract(1, 'year').endOf('year'),
        ];
        break;
    }

    if (period.length) {
      setPeriod(period);
    }
  }, [periodMacros]);

  return (
    <>
      <div style={ { display: 'flex', justifyContent: 'space-between' } }>
        <div>
          <Typography.Text type="secondary">Расчет местрик</Typography.Text>
          <br />
          <div>
            <Space.Compact>
              <DatePicker.RangePicker
                value={ [dayjs(period[0]), dayjs(period[1])] }
                showTime
                allowClear={ true }
                onChange={ (values) => {
                  setPeriod([values[0].toDate(), values[1].toDate()]);
                  setPeriodMacros('other');
                } }
              />
              <Select
                style={ { width: '150px' } }
                value={ periodMacros }
                onChange={ (value) => setPeriodMacros(value) }
                options={ [
                  { label: 'Сегодня', value: 'currentDay' },
                  { label: 'Вчера', value: 'prevDay' },
                  { label: 'Текущий месяц', value: 'currentMonth' },
                  { label: 'Прошлый месяц', value: 'prevMonth' },
                  { label: 'Текущий год', value: 'currentYear' },
                  { label: 'Прошлый год', value: 'prevYear' },
                  { label: 'Другое', value: 'other' },
                ] }
              />
              <Button
                type="primary"
                loading={ isCalcLoading }
                onClick={ () => {
                  remove();
                  calcMetrics();
                } }
              >
                Рассчитать
              </Button>
            </Space.Compact>
            <Dropdown menu={ { items: previewInvoiceFormats } }>
              <a
                onClick={ (e) => e.preventDefault() }
                style={ { marginLeft: '5px' } }
              >
                <Space>
                  Показать счет
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
            <Dropdown menu={ { items: previewActFormats } }>
              <a
                onClick={ (e) => e.preventDefault() }
                style={ { marginLeft: '5px' } }
              >
                <Space>
                  Показать акт
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          <p></p>
          { metrics && metrics.data && <ReactJson src={ metrics.data } /> }
          <Popconfirm
            title="Внимание!"
            description="Будет сформирован счет и акт и отправлен на email клиента!"
            onConfirm={ () => {
              clearInvoice();
              makeInvoice();
            } }
            okText="Да, хорошо"
            cancelText="Отмена"
          >
            <Button danger loading={ isMakingInvoice }>
              Выставить счет за период
            </Button>
          </Popconfirm>
          <p></p>
        </div>
        <br />
        <div>
          <Button
            type="dashed"
            danger
            onClick={ () => syncTeam() }
            loading={ isSyncingTeam }
          >
            Синхронизировать команду
          </Button>
        </div>
      </div>
      <BillingMetricsTable
        teamId={ team.id }
        startDate={ period[0] }
        endDate={ period[1] }
        listProps={ {
          headerProps: {
            breadcrumb: undefined,
          },
        } }
      />
    </>
  );
};

function getQuery(period: Date[]) {
  if (!period || !period.length) {
    return '';
  }

  return Object.entries({
    startAt: period[0].toISOString(),
    endAt: period[1].toISOString(),
  })
    .map(([k, v]) => `${ k }=${ v }`)
    .join('&');
}

export default BillingMetrics;
