import { Tooltip, notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";

export const ShortId = (value: string) => {
  if (!value) {
    return '';
  }

  const text = value.slice(0, 3) + '.' + value.slice(-3);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(value);

    notification.open({
      message: 'ID Copied',
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      duration: 1,
    });
  };

  return (
    <Tooltip title={<span onDoubleClick={copyToClipboard}>{value}</span>}>
      {text}
    </Tooltip>
  )
}
