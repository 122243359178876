import React, { useState } from "react";
import { IResourceComponentsProps, useApiUrl, useCustom } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import {IQuotaModel} from "../models/QuotaModel";
import TransferActions from "../components/TransferActions";
import IdentInput from "./components/IdentInput";

export const QuotaCreate: React.FC<IResourceComponentsProps> = () => {
  const {formProps, saveButtonProps} = useForm<IQuotaModel>();
  const [selectedIdent, setSelectedIdent] = useState('');

  const apiUrl = useApiUrl();
  const quotaSettings = useCustom({
    url: apiUrl + "/quota/settings",
    method: "get",
  })

  if (quotaSettings.isLoading) {
    return <>Loading...</>
  }

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={(changed) => {
          changed.ident && setSelectedIdent(changed.ident);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="IDENT"
          name="ident"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <IdentInput idents={quotaSettings.data.data.idents} />
        </Form.Item>
        <Form.Item
          label="Value"
          name="value"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={
              quotaSettings.data.data.idents[selectedIdent] ?
                quotaSettings.data.data.idents[selectedIdent].values : 'Enter value'
            }
          />
        </Form.Item>
        <Form.Item
          label="Actions"
          name="actions"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TransferActions availableActions={quotaSettings.data.data.actions || []} />
        </Form.Item>
      </Form>
    </Create>
  );
};
