import { useApiUrl, useCustom, useCustomMutation } from '@refinedev/core';
import { Button, Form, Switch } from 'antd';
import { useCallback, useEffect } from 'react';

const RuntimeSettings = () => {
  const [form] = Form.useForm();
  const apiUrl = useApiUrl();

  const { refetch: load, isFetching, data } = useCustom({
    url: `${apiUrl}/runtime/settings`,
    method: 'get',
  })

  const { mutate: save, isLoading: isSaving } = useCustomMutation({
    mutationOptions: {
      retry: false,
    }
  })

  const saveSettings = useCallback((values: any) => {
    save({
      url: `${apiUrl}/runtime/settings`,
      method: 'post',
      values,
      successNotification: (data) => {
        load();
        return {
          message: 'Settings changed',
          type: 'success',
        };
      },
      errorNotification: (data) => {
        return {
          message: 'Settings change fail!',
          type: 'error',
        };
      },
    })
  }, [apiUrl, save])

  useEffect(() => {
    if (!isFetching && data.data) {
      form.setFieldsValue(data.data);
    }
  }, [data, form, isFetching]);

  return (
    <div style={{ background: '#fff', padding: '25px', borderRadius: '6px' }}>
      <Form
        form={form}
        disabled={isFetching || isSaving}
        layout="vertical"
        onFinish={saveSettings}
      >
        <Form.Item
          name="importsAutostart"
          label="importsAutostart"
          extra="Включает автозапуск импортов из очереди"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="billingMetricsAutoSync"
          label="billingMetricsAutoSync"
          extra="Автосинхранизация метрик каждый 1 день месяца"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="sendInvoices"
          label="sendInvoices"
          extra="Включает автоматическое выставление юр. счетов"
        >
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default RuntimeSettings;
