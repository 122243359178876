import { ComponentProps, FC, PropsWithChildren } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "../constants";
import axios from "axios";

type ReactKeycloakProviderProps = ComponentProps<typeof ReactKeycloakProvider>;
type KeycloakProviderProps = Omit<ReactKeycloakProviderProps, "authClient" | "autoRefreshToken">;

const KeycloakProvider: FC<PropsWithChildren<KeycloakProviderProps>> = ({ children, ...props }) => {
  const onTokens: ReactKeycloakProviderProps['onTokens'] = ({ token, idToken }) => {
    if (token && idToken) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      document.cookie = encodeURIComponent('access_token') + '=' + encodeURIComponent(token);
      document.cookie = encodeURIComponent('id_token') + '=' + encodeURIComponent(idToken);
    }
  };

  return (
    <ReactKeycloakProvider
      autoRefreshToken
      authClient={keycloak}
      onTokens={onTokens}
      {...props}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default KeycloakProvider;
