import { ShowButton, ShowButtonProps } from "@refinedev/antd";
import React from "react";

export const ShowLink: React.FC<ShowButtonProps> = ({children, ...props}) => {
  if (!children) {
    return null;
  }
  return (
    <ShowButton
      size="small"
      type="link"
      {...props}
    >
      {children}
    </ShowButton>
  )
}
