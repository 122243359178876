import { useShow, IResourceComponentsProps } from "@refinedev/core";
import { Show, ShowButton } from "@refinedev/antd";
import { Avatar, Badge, Col, Row, Typography } from "antd";
import { TeamOutlined } from "@ant-design/icons";
import React from "react";
import {IUsersModel} from "../../../models/UsersModel";
import EventList from "../../../components/EventList";
import UserStagesTimeline from "../../../components/UserStagesTimeline";
import OrderList from "../../../components/OrderList";
import UserTickets from "../../../components/UserTickets";
import { FREESCOUT_ENABLED } from "../../../constants";
const { Title, Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IUsersModel>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  if (!record) {
    return <Show isLoading />
  }

  return (
    <Show
      isLoading={isLoading}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={2}>
            {record?.name} - {record.email}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={9}>
          <Row gutter={16}>
            <Col span={6}>
              <p>
                <Avatar src={record?.avatarUrl} shape="square" size="large" />
              </p>
              <Badge
                color={record?.suspendedAt ? 'red' : 'green'}
                status={record?.suspendedAt ? 'error' : 'success'}
                text={record?.suspendedAt ? 'Отключен' : 'Активен'}
              />
              <br />
              <Badge
                color={record?.suspendedAt ? 'pink' : 'blue'}
                status={record?.suspendedAt ? 'error' : 'success'}
                text={record?.isAdmin ? "Админ" : "Участник"}
              />
              <hr />
              <p>
                <h5>Team</h5>
                <p>
                  <Avatar src={record?.team?.avatarUrl} shape="square" size="large" />
                </p>
                <ShowButton
                  icon={<TeamOutlined />}
                  recordItemId={record?.teamId}
                  resource="teams"
                >
                  {record?.team?.subdomain}
                </ShowButton>
              </p>
            </Col>
            <Col span={18}>
              <Title level={5}>Email</Title>
              <Text>{record?.email}</Text>
              <Title level={5}>Name</Title>
              <Text>{record?.name}</Text>
              {
                record?.username && (<>
                  <Title level={5}>User Name</Title>
                  <Text>{record?.username}</Text>
                </>)
              }
              <Title level={5}>Флаги</Title>
              <pre>{JSON.stringify(record?.flags)}</pre>
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <Row>
            <Col span={8}>
              <UserStagesTimeline user={record} />
            </Col>
            <Col span={16}>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          { FREESCOUT_ENABLED && <UserTickets email={record.email} /> }
        </Col>
      </Row>
      <OrderList userId={record.id} />
      <EventList actorId={record?.id} />
    </Show>
  );
};
