import React, { memo } from "react";
import {
  TableProps,
  Transfer,
  TransferProps,
} from "antd";
import { TransferItem } from "antd/lib/transfer";
import TransferTable from "./TransferTable";
import { TableRowSelection } from "antd/es/table/interface";
import difference from "lodash/difference";

type TransferTableProps<TransferRecordType> =
  TransferProps<TransferRecordType> & {
    tableProps: Omit<
      TableProps<TransferRecordType>,
      "columns" | "rowSelection"
    > & {
      leftColumns: TableProps<TransferRecordType>["columns"];
      rightColumns: TableProps<TransferRecordType>["columns"];
    };
  };

type TransferTableItem = TransferItem & {
  id: string;
};

function TransferComponent<
  TransferRecordType extends TransferTableItem = TransferTableItem
>({ tableProps, ...transferProps }: TransferTableProps<TransferRecordType>) {
  return (
    <Transfer<TransferRecordType>
      filterOption={() => true}
      rowKey={(row) => row.id}
      {...transferProps}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const rowSelection: TableRowSelection<TransferRecordType> = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
                .filter((item) => !item.disabled)
                .map(({ key }) => key as string);
            const diffKeys = selected
                ? difference(treeSelectedKeys, listSelectedKeys)
                : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key as string, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };
        return <TransferTable
          direction={direction}
          tableProps={tableProps}
          filteredItems={filteredItems}
          rowSelection={rowSelection}
          listSelectedKeys={listSelectedKeys}
          listDisabled={listDisabled}
          targetKeyLength={transferProps.targetKeys?.length}
        />
      }}
    </Transfer>
  );
}

export default memo(TransferComponent) as typeof TransferComponent; // Fixes usage of generic with memo
