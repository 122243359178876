import * as React from "react";
import { useMemo } from "react";
import { CrudFilter, CrudFilters } from "@refinedev/core";
import {
  EditButton,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  TextField,
} from "@refinedev/antd";
import { Button, Select, Space, Table } from "antd";
import { ShortId } from "./ShortId";
import { IUsersModel, MemberTypes } from "../models/UsersModel";
import { DateTimeField } from "./DateTimeField";
import useTableWithSearch from "../hooks/useTableWithSearch";
import { CheckCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import TableTitleWithCount from "./TableTitleWithCount";

const memberType = (user: IUsersModel): MemberTypes => {
  if (user.isViewer) {
    return "guest";
  }
  if (user.isOwner) {
    return "owner";
  }
  if (user.isAdmin) {
    return "admin";
  }
  return "member";
};

type UserListProps = {
  teamId?: string;
  listProps?: object;
};

const UserList: React.FC<UserListProps> = ({ teamId, listProps }) => {
  const permanentFilters = teamId
    ? ([
        {
          field: "teamId",
          operator: "eq",
          value: teamId,
        },
      ] as CrudFilters)
    : undefined;

  const {
    tableData: { tableProps, sorter, tableQueryResult },
    getColumn,
    clear,
  } = useTableWithSearch<IUsersModel>({
    resource: "users",
    permanentFilters,
    filterFields: [
      "name",
      "email",
      "username",
      "deletedAt",
      "isViewer",
      "isAdmin",
      "isOwner",
    ],
    initialSorter: [
      // {
      //   field: "deletedAt",
      //   order: "desc",
      // },
      // {
      //   field: "createdAt",
      //   order: "desc",
      // },
    ],
  });

  const columns = useMemo<ColumnsType<IUsersModel>>(() => {
    return [
      {
        key: "id",
        dataIndex: "id",
        title: "ID",
        render: ShortId,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <Select
              mode="tags"
              style={{ width: "100%", minWidth: "200px" }}
              placeholder="Select ID"
            />
          </FilterDropdown>
        ),
      },
      {
        ...getColumn("email"),
        dataIndex: "email",
        key: "email",
        title: "Email",
        render: (value, record: IUsersModel) => (
          <ShowButton size="small" resource="users" recordItemId={record.id}>
            <TextField value={value} />
          </ShowButton>
        ),
        defaultSortOrder: getDefaultSortOrder("email", sorter),
        sorter: true,
      },
      {
        dataIndex: "name",
        key: "name",
        title: "Name",
        defaultSortOrder: getDefaultSortOrder("name", sorter),
        sorter: true,
        ...getColumn("name"),
      },
      {
        dataIndex: ["team", "name"],
        key: "team.name",
        title: "Team",
        render: (value, record: IUsersModel) => (
          <ShowButton
            size="small"
            resource="teams"
            recordItemId={record.teamId}
          >
            <TextField value={value} />
          </ShowButton>
        ),
        defaultSortOrder: getDefaultSortOrder("team.name", sorter),
        sorter: true,
      },
      {
        dataIndex: "memberType",
        title: "Type",
        render: (value, record) => memberType(record),
        defaultSortOrder: "desc",
        sorter: false,
      },
      {
        dataIndex: "isViewer",
        key: "isViewer",
        title: "Viewer",
        align: "center",
        render: (value) =>
          value ? <CheckCircleOutlined /> : <MinusOutlined />,
        defaultSortOrder: getDefaultSortOrder("isViewer", sorter),
        sorter: true,
        filters: [
          { text: "True", value: true },
          { text: "False", value: false },
        ],
      },
      {
        dataIndex: "isAdmin",
        key: "isAdmin",
        title: "Admin",
        align: "center",
        render: (value) =>
          value ? <CheckCircleOutlined /> : <MinusOutlined />,
        defaultSortOrder: getDefaultSortOrder("isAdmin", sorter),
        sorter: true,
        filters: [
          { text: "True", value: true },
          { text: "False", value: false },
        ],
      },
      {
        dataIndex: "isOwner",
        key: "isOwner",
        title: "Owner",
        align: "center",
        render: (value) =>
          value ? <CheckCircleOutlined /> : <MinusOutlined />,
        defaultSortOrder: getDefaultSortOrder("isOwner", sorter),
        sorter: true,
        filters: [
          { text: "True", value: true },
          { text: "False", value: false },
        ],
      },
      {
        dataIndex: "lastActiveAt",
        key: "lastActiveAt",
        title: "lastActiveAt",
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder("lastActiveAt", sorter),
        sorter: true,
      },
      {
        dataIndex: "createdAt",
        key: "createdAt",
        title: "createdAt",
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder("createdAt", sorter),
        sorter: true,
      },
      {
        dataIndex: "deletedAt",
        key: "deletedAt",
        title: "deletedAt",
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder("deletedAt", sorter),
        sorter: true,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (_, record) => (
          <Space>
            <EditButton hideText size="small" recordItemId={record.id} />
          </Space>
        ),
      },
    ] as ColumnsType<IUsersModel>;
  }, [getColumn, sorter]);

  return (
    <List
      title={
        <TableTitleWithCount
          title="Users"
          tableQueryResult={tableQueryResult}
        />
      }
      headerButtons={({ defaultButtons }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
      {...listProps}
    >
      <Table {...tableProps} columns={columns} size="small" rowKey="id" />
    </List>
  );
};

export default UserList;
