import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import React from "react";
import {IDefaultEntity, IDefaultModel} from "./index";

export const DefaultCrudEdit: React.FC<IResourceComponentsProps & { model: IDefaultModel }> = ({ model }) => {
  const { formProps, saveButtonProps } = useForm<IDefaultEntity>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        {
          Object.keys(model).map(columnName => {
            return <Form.Item
              label={columnName} name={columnName}
            >
              <Input />
            </Form.Item>
          })
        }
      </Form>
    </Edit>
  );
};
