import React, { FC, useState } from "react";
import { IResourceComponentsProps, useApiUrl, useCustom } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { IQuotaModel } from "../models/QuotaModel";
import IdentInput from "./components/IdentInput";

export const QuotaEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IQuotaModel>();
  const [selectedIdent, setSelectedIdent] = useState('');

  const apiUrl = useApiUrl();
  const quotaSettings = useCustom({
    url: apiUrl + "/quota/settings",
    method: "get",
  })

  if (quotaSettings.isLoading) {
    return <>Loading...</>
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={(changed) => {
          changed.ident && setSelectedIdent(changed.ident);
        }}
      >
        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="IDENT" name="ident">
          <IdentInput idents={quotaSettings.data.data.idents} />
        </Form.Item>
        <Form.Item label="Value" name="value">
          <Input
            placeholder={
              quotaSettings.data.data.idents[selectedIdent] ?
                quotaSettings.data.data.idents[selectedIdent].values : 'Enter value'
            }
          />
        </Form.Item>
        <Form.Item label="Actions" name="actions">
          <Select mode="multiple" options={quotaSettings.data.data.actions.map(action => ({label: action, value: action}))} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
