import React from "react";
import { Table } from "antd";

const TransferTable = ({ direction, tableProps, filteredItems, rowSelection, listSelectedKeys, listDisabled, targetKeyLength }: any) => {
  const columns =
    direction === "left"
      ? tableProps.leftColumns
      : tableProps.rightColumns;

  return <>
    <Table
      size="small"
      columns={columns}
      dataSource={filteredItems}
      rowSelection={rowSelection}
      style={{ pointerEvents: listDisabled ? "none" : undefined }}
      pagination={{
        total: direction === 'right' ? targetKeyLength : tableProps.pagination.total - targetKeyLength,
        pageSize: 100,
        ...tableProps,
      }}
      loading={tableProps.loading}
      onChange={(props) => {
        tableProps.onChange(props)
      }}
    />
  </>
}
export default TransferTable
