import React, { FC, useState } from "react";
import { Button, Col, Row } from "antd";
import useTableWithSearch from "../../../hooks/useTableWithSearch";
import TeamTable from "../../Yonote/team/components/TeamTable";
import { ITariffModel } from "../models/TariffModel";
import TeamSelectionModal from "./TeamSelectionModal";

interface TransferTeamsProps {
  tariffId: string | undefined;
  value?: string[];
  onChange?: (items: string[]) => void;
}

const TransferTeams: FC<TransferTeamsProps> = ({
  tariffId,
  value,
  onChange,
}) => {
  const [show, setShow] = useState(false);

  // Получение команд которые входят в данный тариф
  const {
    tableData: { tableProps, sorter, tableQueryResult },
    getColumn,
    clear,
  } = useTableWithSearch<ITariffModel>({
    resource: "teams",
    meta: {
      join: {
        field: "tariffToTeam",
      },
    },
    permanentFilters: [
      {
        field: "tariffToTeam.isActive",
        operator: "eq",
        value: true,
      },
      {
        field: "tariffToTeam.tariffId",
        operator: "eq",
        value: tariffId,
      },
    ],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filterFields: ["name", "subdomain", "domain"],
  });

  return (
    <Col>
      <Row justify="end">
        <Button onClick={clear}>Clear filters</Button>
        <Button
          justify-content="end"
          style={{ margin: "0 0 15px 15px" }}
          onClick={() => setShow(true)}
        >
          Add a team
        </Button>
      </Row>
      <TeamSelectionModal
        tariffId={tariffId}
        show={show}
        setShow={setShow}
        onChange={() => tableQueryResult.refetch()}
      />
      <TeamTable
        isDeleted
        refetch={() => tableQueryResult.refetch()}
        tableProps={tableProps}
        getColumn={getColumn}
        sorter={sorter}
      />
    </Col>
  );
};

export default TransferTeams;
