import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Form, Input, Row, Col, Switch, Select } from "antd";
import { Edit, useForm } from "@refinedev/antd";
import "react-mde/lib/styles/css/react-mde-all.css";
import { ITariffModel, ITariffToTeam } from "../models/TariffModel";
import TransferTeams from "../components/TransferTeams";
import TransferQuotas from "../components/TransferQuotas";

export const TariffEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ITariffModel>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Price (with 00!)"
                  name="price"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="PriceType"
                  name="priceType"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      {
                        value: "monthly",
                      },
                      {
                        value: "yearly",
                      },
                      {
                        value: "onetime",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={7}>
                <Form.Item
                  label="Tariff is Active"
                  name="isActive"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Public tariff"
                  name="isPublic"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Default tariff"
                  name="isDefault"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form.Item
          label="Quota in tariff"
          name={["quotas"]}
          getValueProps={(items?: { id: string }[]) => {
            return { value: items?.map((item) => item.id) };
          }}
          getValueFromEvent={(args: string[]) => {
            return args.map((item) => ({
              id: item,
            }));
          }}
        >
          <TransferQuotas />
        </Form.Item>

        <Form.Item
          label="Teams in tariff"
          name={["tariffToTeam"]}
          getValueProps={(items?: ITariffToTeam[]) => {
            return { value: items?.map((item) => item.teamId) };
          }}
          getValueFromEvent={(args: string[]) => {
            return args.map((item) => ({
              teamId: item,
              tariffId: queryResult?.data?.data.id,
              isActive: true,
            }));
          }}
        >
          <TransferTeams tariffId={queryResult?.data?.data.id} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
