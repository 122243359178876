import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { List } from "@refinedev/antd";
import { Button } from "antd";
import { ITeamModel } from "../models/TeamModel";
import useTableWithSearch from "../../../hooks/useTableWithSearch";
import TeamTable from "./components/TeamTable";

export const TeamList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableData: { tableProps, sorter },
    getColumn,
    clear,
  } = useTableWithSearch<ITeamModel>({
    resource: "teams",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    filterFields: ["name", "subdomain", "domain"],
  });

  return (
    <List
      title="Teams"
      canCreate={false}
      headerProps={{ breadcrumb: undefined }}
      headerButtons={({ defaultButtons }: { defaultButtons: any }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
     <TeamTable
       tableProps={tableProps}
       getColumn={getColumn}
       sorter={sorter}
     />
    </List>
  );
};
