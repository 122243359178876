import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  List,
  TextField,
  getDefaultSortOrder,
  EditButton,
  DeleteButton,
  DateField,
  ShowButton,
  FilterDropdown,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Button,
  Switch,
  Select,
} from "antd";
import { ITariffModel } from "../models/TariffModel";
import useTableWithSearch from "../../../hooks/useTableWithSearch";
import { ShortId } from "../../../components/ShortId";

export const TariffList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableData: {
      tableProps,
      sorter,
    },
    getColumn,
    clear,
  } = useTableWithSearch<ITariffModel>({
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ]
    },
    filterFields: ["name", "description"],
  });

  return (
    <List
      headerButtons={({ defaultButtons }) => (
        <>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
    >
      <Table {...tableProps} size="small" rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={ShortId}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="tags"
                style={{ width: '100%', minWidth: '200px' }}
                placeholder="Select ID"
              />
            </FilterDropdown>
          )}
        />
        <Table.Column<ITariffModel>
          dataIndex="name"
          key="name"
          title="Name"
          {...getColumn("name")}
          render={(value, record) => {
            return (
              <ShowButton recordItemId={record.id}>
                <TextField value={value} />
              </ShowButton>
            );
          }}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<ITariffModel>
          dataIndex="description"
          key="description"
          title="Description"
          {...getColumn("description")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("description", sorter)}
          sorter
        />
        <Table.Column<ITariffModel>
          dataIndex="isActive"
          key="isActive"
          title="Active"
          render={(value) => <Switch checked={value} disabled={true} />}
          defaultSortOrder={getDefaultSortOrder("isActive", sorter)}
          sorter
        />
        <Table.Column<ITariffModel>
          dataIndex="isDefault"
          key="isDefault"
          title="Default"
          render={(value) => <Switch checked={value} disabled={true} />}
          defaultSortOrder={getDefaultSortOrder("isDefault", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="createdAt"
          render={(value) => <DateField value={value} />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="updatedAt"
          render={(value) => <DateField value={value} />}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
        <Table.Column<ITariffModel>
          title="Actions"
          dataIndex="actions"
          key="actions"
          render={(_text, record) => {
            return (
              <Space>
                <EditButton recordItemId={record.id} size="small" />
                <DeleteButton recordItemId={record.id} size="small" />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
