import { EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  DeleteButton,
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  TextField,
} from "@refinedev/antd";
import { CrudFilters } from "@refinedev/core";
import { Button, Select, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useMemo } from "react";
import useMetaViewer from "../hooks/useMetaViewer";
import useTableWithSearch from "../hooks/useTableWithSearch";
import { IBillingMetrics } from "../pages/Billing/models/BillingMetricsModel";
import { DateTimeField } from "./DateTimeField";
import { ShortId } from "./ShortId";
import TableTitleWithCount from "./TableTitleWithCount";

export type BillingMetricsTableProps = {
  teamId?: string;
  startDate?: Date;
  endDate?: Date;
  listProps?: object;
};

const BillingMetricsTable: React.FC<BillingMetricsTableProps> = ({
  teamId,
  startDate,
  endDate,
  listProps,
}) => {
  const { showMeta, metaComponent } = useMetaViewer();

  const permanentFilters = useMemo(() => {
    const filters: CrudFilters = [];

    if (teamId) {
      filters.push({
        field: "teamId",
        operator: "eq",
        value: teamId,
      });
    }

    if (startDate) {
      filters.push({
        field: "createdAt",
        operator: "gte",
        value: startDate,
      });
    }

    if (endDate) {
      filters.push({
        field: "createdAt",
        operator: "lte",
        value: endDate,
      });
    }

    return filters;
  }, [endDate, startDate, teamId]);

  const {
    tableData: { tableProps, sorter, tableQueryResult },
    getColumn,
    clear,
  } = useTableWithSearch<IBillingMetrics>({
    resource: "billing_metrics",
    permanentFilters,
    filterFields: ["id", "value", "unit", "meta"],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
  });

  const columns = useMemo<ColumnsType<IBillingMetrics>>(() => {
    return [
      {
        key: "id",
        dataIndex: "id",
        title: "ID",
        render: ShortId,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <Select
              mode="tags"
              style={{ width: "100%", minWidth: "200px" }}
              placeholder="Select ID"
            />
          </FilterDropdown>
        ),
      },
      {
        ...getColumn("value"),
        dataIndex: "value",
        key: "value",
        title: "Value",
        defaultSortOrder: getDefaultSortOrder("value", sorter),
        sorter: true,
      },
      {
        ...getColumn("unit"),
        dataIndex: "unit",
        key: "unit",
        title: "Unit",
        defaultSortOrder: getDefaultSortOrder("unit", sorter),
        sorter: true,
      },
      {
        key: "meta",
        title: "Type",
        render: (_, record) => <MetaTypeLabel metric={record} />,
      },
      {
        dataIndex: ["team", "name"],
        key: "team.name",
        title: "Team",
        render: (value, record: IBillingMetrics) => (
          <ShowButton
            size="small"
            resource="teams"
            recordItemId={record.teamId}
          >
            <TextField value={value} />
          </ShowButton>
        ),
        defaultSortOrder: getDefaultSortOrder("team.name", sorter),
        sorter: true,
      },
      {
        dataIndex: "createdAt",
        key: "createdAt",
        title: "createdAt",
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder("createdAt", sorter),
        sorter: true,
      },
      {
        dataIndex: "deletedAt",
        key: "deletedAt",
        title: "deletedAt",
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder("deletedAt", sorter),
        sorter: true,
      },
      {
        title: "",
        dataIndex: "actions",
        render: (_, record) => (
          <Space>
            {record.meta ? (
              <Button
                size="small"
                icon={<EyeOutlined />}
                title="Show Meta"
                onClick={() => showMeta(record.meta)}
              />
            ) : null}
            <DeleteButton
              size="small"
              resource="billing_metrics"
              recordItemId={record.id}
              hideText
            />
          </Space>
        ),
      },
    ] as ColumnsType<IBillingMetrics>;
  }, [getColumn, showMeta, sorter]);

  return (
    <List
      title={
        <TableTitleWithCount
          title="Billing Metrics"
          tableQueryResult={tableQueryResult}
        />
      }
      headerButtons={({ defaultButtons }) => (
        <>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => tableQueryResult.refetch()}
            loading={tableQueryResult.isLoading}
          >
            Reload
          </Button>
          <Button onClick={clear}>Clear filters</Button>
          {defaultButtons}
        </>
      )}
      {...listProps}
    >
      <Table {...tableProps} columns={columns} size="small" rowKey="id" />
      {metaComponent}
    </List>
  );
};

const MetaTypeLabel = ({ metric }: { metric: IBillingMetrics }) => {
  if (metric.meta.type === "sync") {
    return <Tag color="blue">sync</Tag>;
  } else if (metric.value > 0) {
    return <Tag color="green">event</Tag>;
  } else {
    return <Tag color="orange">event</Tag>;
  }
};

export default BillingMetricsTable;
