import { useShow, IResourceComponentsProps } from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Typography } from "antd";
import React from "react";
import {IDefaultEntity, IDefaultModel} from "./index";

const {Title, Text} = Typography;

export const DefaultCrudShow: React.FC<IResourceComponentsProps & { model: IDefaultModel }> = ({model}) => {
  const {queryResult} = useShow<IDefaultEntity>();
  const {data, isLoading} = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      {
        record ?
          Object.keys(model).map(columnName => {
            return <>
              <Title level={5}>{columnName}</Title>
              <Text>{record[columnName] || ''}</Text>
            </>
          })
          : null
      }
    </Show>
  );
};
