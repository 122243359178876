import React from "react";
import { MetricsList } from "./metrics";
import { QuotaCreate, QuotaList } from "./quota";
import { AccessControl } from "accesscontrol";
import { AppRoles } from "../../ac";
import { TariffCreate, TariffEdit, TariffList, TariffShow } from "./tariff";
import { OrderCreate, OrderShow, OrdersList } from "./order";
import { DiscountCreate, DiscountList } from "./discount";
import { ModuleInterface } from "@interfaces";
import { QuotaEdit } from "./quota";
import { Route } from "react-router-dom";
import { QuotaShow } from "./quota/show";
import {
  PaymentMethodCreate,
  PaymentMethodEdit,
  PaymentMethodList,
} from "./payment-method";
import AuditOutlined from "@ant-design/icons/lib/icons/AuditOutlined";
import ReconciliationOutlined from "@ant-design/icons/lib/icons/ReconciliationOutlined";
import ShopOutlined from "@ant-design/icons/lib/icons/ShopOutlined";
import PercentageOutlined from "@ant-design/icons/lib/icons/PercentageOutlined";
import UnlockOutlined from "@ant-design/icons/lib/icons/UnlockOutlined";
import LineChartOutlined from "@ant-design/icons/lib/icons/LineChartOutlined";

const BillingModule: ModuleInterface = {
  accessControl: (ac: AccessControl) => {
    ac.grant(AppRoles.ADMIN)
      .read(["quota", "tariff", "order", "discount", "payment_method"])
      .create(["quota", "tariff", "order", "discount", "payment_method"])
      .update(["quota", "tariff", "order", "discount", "payment_method"])
      .delete(["quota", "tariff", "order", "discount", "payment_method"]);

    return ac;
  },
  resources: [
    {
      name: "order",
      meta: { label: "Orders", icon: <AuditOutlined /> },
      list: OrdersList,
      create: OrderCreate,
      show: OrderShow,
    },
    {
      name: "billing_metrics",
      meta: {
        label: "Metrics",
        icon: <LineChartOutlined />,
      },
      list: MetricsList,
      create: DiscountCreate,
      edit: DiscountCreate,
    },
    {
      name: "tariff",
      meta: { label: "Tariff", icon: <ShopOutlined /> },
      list: TariffList,
      create: TariffCreate,
      edit: TariffEdit,
      show: TariffShow,
    },
    {
      name: "discount",
      meta: {
        label: "Discount",
        icon: <PercentageOutlined />,
      },
      list: DiscountList,
      create: DiscountCreate,
      edit: DiscountCreate,
    },
    {
      name: "quota",
      meta: { label: "Quota", icon: <UnlockOutlined /> },
      list: QuotaList,
      create: QuotaCreate,
      edit: QuotaEdit,
    },
    {
      name: "payment_method",
      meta: { label: "PaymentMethod", icon: <ReconciliationOutlined /> },
      list: PaymentMethodList,
      create: PaymentMethodCreate,
      edit: PaymentMethodEdit,
    },
  ],
  routes: [
    <Route key={"order"} path="/order">
      <Route index element={<OrdersList />} />
      <Route path="create" element={<OrderCreate />} />
      <Route path="show/:id" element={<OrderShow />} />
    </Route>,
    <Route key={"billing_metrics"} path="/billing_metrics">
      <Route index element={<MetricsList />} />
    </Route>,
    <Route key={"tariff"} path="/tariff">
      <Route index element={<TariffList />} />
      <Route path="create" element={<TariffCreate />} />
      <Route path="show/:id" element={<TariffShow />} />
      <Route path="edit/:id" element={<TariffEdit />} />
    </Route>,
    <Route key={"discount"} path="/discount">
      <Route index element={<DiscountList />} />
      <Route path="create" element={<DiscountCreate />} />
    </Route>,
    <Route key={"quota"} path="/quota">
      <Route index element={<QuotaList />} />
      <Route path="create" element={<QuotaCreate />} />
      <Route path="show/:id" element={<QuotaShow />} />
      <Route path="edit/:id" element={<QuotaEdit />} />
    </Route>,
    <Route key={"payment_method"} path="/payment_method">
      <Route index element={<PaymentMethodList />} />
      <Route path="create" element={<PaymentMethodCreate />} />
      <Route path="edit/:id" element={<PaymentMethodEdit />} />
    </Route>,
  ],
};

export default BillingModule;
