import {IDefaultDbModel} from "@interfaces";
import { IUsersModel } from "../../../models/UsersModel";
import { ITeamModel } from "../../Yonote/models/TeamModel";
import { ITariffModel } from "./TariffModel";
import { IDiscountModel } from "./DiscountModel";
import { ITransactionModel } from "./TransactionModel";

export const OrderStatuses = [
  'created',
  'pending',
  'canceled',
  'success',
  'fail',
]

export const OrderTypes = [
  'purchase',
  'subscription',
  'scheduled',
  'check',
]

export type OrderStatus =
  'created' |
  'pending' |
  'canceled' |
  'success' |
  'fail'
  ;

export type OrderType =
  'purchase' |
  'subscription' |
  'scheduled' |
  'check'
  ;

export interface IOrderModel extends IDefaultDbModel {
  id: number
  teamId: string
  userId: string
  tariffId: string
  discountId: string
  type: OrderType
  status: OrderStatus
  tariffCost: number
  price: number
  cost: number
  description: string

  user: IUsersModel
  team: ITeamModel
  tariff: ITariffModel
  discount: IDiscountModel
  transactions: ITransactionModel[]
}
