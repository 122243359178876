import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react";
import { DeleteButton } from "@refinedev/antd";
import { Table } from "antd";
import { useList, useUpdate } from "@refinedev/core";
import { ITariffToTeam } from "../pages/Billing/models/TariffModel";
import { ShortId } from "./ShortId";
import { DateTimeField } from "./DateTimeField";
import { ShowLink } from "./ShowLink";
import { Switch } from "antd";

type TariffTableProps = {
  teamId?: string
  userId?: string
  readonly?: boolean
  onChange?: () => void
  setUpdateTableTariff?: Dispatch<SetStateAction<(() => void) | undefined>>
}

export const TariffTable: React.FC<TariffTableProps> = ({
  teamId,
  readonly,
  setUpdateTableTariff,
}) => {
  const [localTable, setLocalTable] = useState<ITariffToTeam[]>([]);
  const { mutate } = useUpdate();

  const listQueryResult = useList<ITariffToTeam>({
    resource: 'tariff_to_team',
    config: {
      sort: [
        { field: 'createdAt', order: 'desc' },
      ],
      filters: [
        {
          field: 'teamId',
          operator: 'eq',
          value: teamId,
        }
      ]
    }
  })

  useEffect(() => {
    setLocalTable(listQueryResult.data?.data || []);
    setUpdateTableTariff && setUpdateTableTariff(listQueryResult.refetch)
  }, [listQueryResult.data?.data, listQueryResult.refetch, setUpdateTableTariff])

  const onChangeParams = useCallback((id: string, fieldName: string, value: boolean) => {
    mutate({
      resource: 'tariff_to_team',
      id: id,
      values: {
        [fieldName]: value
      }
    })
    listQueryResult.refetch()
  }, [listQueryResult, mutate])

  return (
    <Table dataSource={localTable} size="small">
      <Table.Column dataIndex="tariffToTeamId" title="ID" render={ShortId} />
      <Table.Column
        dataIndex={['tariff','name']}
        title="Tariff"
        render={(value, record: ITariffToTeam) => (
          <ShowLink
            resource="tariff"
            recordItemId={record.tariffId}
          >
            {value}
          </ShowLink>
        )}
      />
      <Table.Column
        dataIndex="isActive"
        title="Active"
        align="center"
        render={(value, record: ITariffToTeam) => (
          <Switch
            size="small"
            disabled={readonly}
            checked={value}
            onChange={val => onChangeParams(record.tariffToTeamId, 'isActive', val)}
          />
        )}
      />
      <Table.Column
        dataIndex="isDefault"
        title="Default"
        align="center"
        render={(value, record: ITariffToTeam) => (
          <Switch
            size="small"
            disabled={readonly}
            checked={value}
            onChange={val => onChangeParams(record.tariffToTeamId, 'isDefault', val)}
          />
        )}
      />
      <Table.Column dataIndex="createdAt" title="Created" render={DateTimeField} />
      <Table.Column
        key="actions"
        title=""
        render={(v, record: ITariffToTeam) => (<>
          <DeleteButton
            size="small"
            hideText
            recordItemId={record.tariffToTeamId}
            resource="tariff_to_team"
            accessControl={{
              enabled: false
            }}
          />
        </>)} />
    </Table>
  )
}
