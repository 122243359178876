import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import OrderList from "../../../components/OrderList";

export const OrdersList: React.FC<IResourceComponentsProps> = () => {
  return (
    <OrderList
      canCreate={true}
    />
  );
};
