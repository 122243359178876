import { ITeamModel } from "../../models/TeamModel";
import {
  DateField,
  FilterDropdown,
  getDefaultSortOrder,
  ShowButton,
} from "@refinedev/antd";
import {
  Popconfirm,
  Select,
  Table,
} from "antd";
import { ShortId } from "../../../../components/ShortId";
import React from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useUpdate } from "@refinedev/core";

type TeamTableProps = {
  tableProps: any,
  sorter: any,
  getColumn: any,
  selectedRowKeys?: string[],
  onSelect?: (_record: Object, _selected: boolean, selectRowKey: ITeamModel[]) => void,
  isDeleted?: boolean;
  refetch?: any;
}

const TeamTable = ({ tableProps, sorter, getColumn, onSelect, isDeleted, selectedRowKeys, refetch }: TeamTableProps) => {
  const { mutate } = useUpdate();

  const deleteTeam = (id: string) => {
    mutate({
      id: id,
      resource: `tariff_to_team`,
      values: {
        isActive: false
      }
    })
    if (refetch) {
      refetch()
    }
  }

  return (
    <Table<ITeamModel>
      {...tableProps}
      size="small"
      rowKey="id"
      rowSelection={onSelect && selectedRowKeys && {
        onSelect: onSelect,
        selectedRowKeys: selectedRowKeys
      }}
    >
      <Table.Column
        dataIndex="id"
        key="id"
        title="ID"
        render={ShortId}
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              mode="tags"
              style={{ width: '100%', minWidth: '200px' }}
              placeholder="Select ID"
            />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex="subdomain"
        key="subdomain"
        title="Subdomain"
        {...getColumn("subdomain")}
        render={(value, record: ITeamModel) => (
          <ShowButton
            type="link"
            recordItemId={record?.id}
            resource="teams"
          >
            {value}
          </ShowButton>
        )}
        sorter
      />
      <Table.Column
        dataIndex="domain"
        key="domain"
        title="Domain"
        sorter
        {...getColumn("domain")}
      />
      <Table.Column
        dataIndex="name"
        key="name"
        title="Name"
        sorter
        {...getColumn("name")}
      />
      <Table.Column
        dataIndex="membersCount"
        key="membersCount"
        title="Members"
        sorter
      />
      <Table.Column
        dataIndex="viewersCount"
        key="viewersCount"
        title="Guests"
        sorter
      />
      <Table.Column
        dataIndex="usersCount"
        key="usersCount"
        title="Total users"
        sorter
      />
      <Table.Column
        dataIndex="createdAt"
        key="createdAt"
        title="createdAt"
        render={(value) => <DateField format="DD.MM.YY HH:mm:ss" value={value} />}
        defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
        sorter
      />
      {isDeleted && <Table.Column<ITeamModel>
        title="Actions"
        dataIndex="actions"
        key="actions"
        render={(_text, record) => {
          return (
            <Popconfirm
              key="delete"
              okText="Delete"
              cancelText="Cancel"
              okType="danger"
              title="Are you sure?"
              onConfirm={() => {
                deleteTeam(record.tariffToTeam[0].tariffToTeamId)
              }}
            >
              <Button
                danger
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Popconfirm>
          );
        }}
      />
      }
    </Table>
  );
}
export default TeamTable
